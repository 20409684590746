import {
  IFieldTypes,
  IFieldGroupBuilt,
  IConditionComparisonOperationsEnum,
} from "../typings";
import { PERMISSION_ARRAY } from "../permission.maps";

import { CONTACT_FORM_FIELD_GROUP } from "./forms/external-user-contact-info";
import { ADDRESS_FORM_FIELDS } from "./forms/address-form";

export const FORM_GLOBAL_ERROR_KEY = "__root";

export const OBJECT_STANDARD_FIELDS: IFieldGroupBuilt = {
  name: "objectStandards",
  label: "Metadata",
  min: 1,
  max: 1,
  fields: [
    {
      name: "description",
      label: "Description",
      fieldType: IFieldTypes.textField,
    },
    // TODO: Update tags to be an autocomplete field what thats deployed
    {
      name: "tags",
      label: "tags",
      fieldType: IFieldTypes.textField,
    },
    {
      name: "dueDate",
      label: "Due Date",
      fieldType: IFieldTypes.dateField,
    },
  ],
};

export const FIELD_FORM: IFieldGroupBuilt = {
  fields: [
    {
      name: "fieldType",
      label: "Field Type",
      fieldType: IFieldTypes.select,
      required: true,
      options: [
        {
          value: "textField",
          key: "textField",
          label: "Short Text",
        },
        {
          value: "textArea",
          key: "textArea",
          label: "Long Text",
        },
        {
          value: "select",
          key: "select",
          label: "Select",
        },
        {
          value: "toggle",
          key: "toggle",
          label: "Toggle",
        },
        {
          value: "radio",
          key: "radio",
          label: "Radio",
        },
      ],
    },
    {
      fieldType: IFieldTypes.toggle,
      label: "Required",
      name: "required",
      description: "this is a description",
      defaultValue: false,
    },
    {
      fieldType: IFieldTypes.textField,
      name: "name",
      label: "Name",
      description: "this is a description",
      defaultValue: "testing default vale",
      required: true,
    },

    {
      fieldType: IFieldTypes.password,
      name: "password",
      label: "Password",
      required: true,
    },
    {
      fieldType: IFieldTypes.textArea,
      name: "description",
      label: "Description",
      max: 2500,
      description: "this is a description",
      defaultValue: `aoethu antoheu atohe ustanohe ustnaoheustnahoe ustnaho 
        eusnthao esutnah`,
    },
    {
      fieldType: IFieldTypes.dateField,
      label: "whens the meeting",
      name: "testing",
      required: true,
      disabled: true,
    },
    {
      fieldType: IFieldTypes.checkbox,
      label: "checkbox test",
      name: "checkboxTes",
      description: "this is a description",
      defaultValue: true,
      disabled: true,
    },
    {
      fieldType: IFieldTypes.textField,
      name: "label",
      label: "Label",
      required: true,
    },
  ],
  groups: [
    {
      fields: [
        {
          fieldType: IFieldTypes.textField,
          label: "name",
          name: "name",
        },
      ],
      max: 33,
      name: "Node",
      label: "Node",
      groups: [
        {
          min: 1,
          max: 2,
          name: "children",
          label: "child node",
          fields: [
            {
              fieldType: IFieldTypes.textField,
              label: "name",
              name: "name",
            },
          ],
          groups: [
            {
              min: 2,
              max: 5,
              name: "children",
              label: "child node",
              fields: [
                {
                  fieldType: IFieldTypes.textField,
                  label: "name",
                  name: "name",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      conditions: [
        {
          parameter: ".fieldType",
          operation: IConditionComparisonOperationsEnum.in,
          value: ["select", "radio"],
        },
      ],
      name: "options",
      label: "Options",
      min: 10,
      max: 999,
      fields: [
        {
          name: "value",
          label: "Value",
          required: true,
          fieldType: IFieldTypes.textField,
          max: 122,
        },
        {
          name: "label",
          label: "Label",
          required: true,
          fieldType: IFieldTypes.textField,
        },
      ],
    },
  ],
  min: 1,
  max: 1,
  label: "Field",
  name: "Field",
};

export const ADD_ORGANIZATION_FORM: IFieldGroupBuilt = {
  name: "addOrganization",
  label: "Add Organization",
  fields: [
    {
      name: "name",
      label: "Name",
      fieldType: IFieldTypes.textField,
      span: 2,
      required: true,
    },
    {
      name: "id",
      label: "Short Code",
      fieldType: IFieldTypes.textField,
      span: 2,
      required: true,
    },
  ],
  groups: [
    ADDRESS_FORM_FIELDS,
    {
      ...CONTACT_FORM_FIELD_GROUP,
      name: "contactInformation",
      label: "Contact Information",
      min: 1,
      max: 20,
    },
  ],
};

export const ADD_DIVISION_FORM: IFieldGroupBuilt = {
  name: "addDivision",
  label: "Add Division",
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "name",
      label: "Name",
      required: true,
    },
    {
      fieldType: IFieldTypes.textField,
      span: 1,
      name: "id",
      label: "Short Code",
      required: true,
    },
    {
      name: "timeZone",
      span: 1,
      label: "Time Zone",
      fieldType: IFieldTypes.timezone,
    },
    {
      fieldType: IFieldTypes.toggle,
      span: 2,
      name: "active",
      label: "Active",
    },
  ],
  groups: [{ ...ADDRESS_FORM_FIELDS, min: 1, max: 1 }],
};

export const EDIT_DIVISION_FORM: IFieldGroupBuilt = {
  name: "editDivision",
  label: "Edit Division",
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "name",
      label: "Name",
      required: true,
    },
    {
      name: "timeZone",
      span: 1,
      label: "Time Zone",
      fieldType: IFieldTypes.timezone,
    },
    {
      fieldType: IFieldTypes.toggle,
      span: 1,
      name: "active",
      label: "Active",
    },
  ],
  groups: [{ ...ADDRESS_FORM_FIELDS, min: 1, max: 1 }],
};

export const EDIT_USER_PERMISSIONS: IFieldGroupBuilt = {
  name: "editPermissions",
  label: "Permissions",
  fields: [
    {
      fieldType: IFieldTypes.autocomplete,
      multiple: true,
      name: "permissions",
      label: "Permissions",
      options: PERMISSION_ARRAY.map((val) => ({
        value: val.name,
        label: val.name,
      })),
    },
  ],
};

export const ADD_SITE_FORM: IFieldGroupBuilt = {
  name: "addSite",
  label: "Add Site",
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "name",
      label: "Name",
      required: true,
    },
    {
      name: "timeZone",
      span: 1,
      label: "Time Zone",
      fieldType: IFieldTypes.timezone,
    },
    {
      fieldType: IFieldTypes.toggle,
      span: 2,
      name: "active",
      label: "Active",
    },
  ],
  groups: [{ ...ADDRESS_FORM_FIELDS, min: 1, max: 1 }],
};

export const EDIT_SITE_FORM: IFieldGroupBuilt = {
  name: "editSite",
  label: "Edit Site",
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "name",
      label: "Name",
      required: true,
    },
    {
      name: "timeZone",
      span: 1,
      label: "Time Zone",
      fieldType: IFieldTypes.timezone,
    },
    {
      fieldType: IFieldTypes.toggle,
      span: 1,
      name: "active",
      label: "Active",
    },
  ],
  groups: [{ ...ADDRESS_FORM_FIELDS, min: 1, max: 1 }],
};
