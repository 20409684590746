import { IClientTarget, ISiteTarget, isClientTarget } from "../typings";

export function getGlobalUserAccessKey(
  target: IClientTarget | ISiteTarget,
): string {
  if (isClientTarget(target)) {
    const { orgId, tenantId, divisionId } = target;

    if (divisionId && tenantId) {
      return `${orgId}::${tenantId}::${divisionId}`;
    }
    if (tenantId) {
      return `${orgId}::${tenantId}`;
    }
    return orgId;
  } else {
    return "_s::" + target.siteId;
  }
}

export function getTargetFromAccessKey(
  key: string,
): IClientTarget | ISiteTarget {
  const [orgId, tenantId, divisionId] = key.split("::");
  if (orgId === "_s") {
    // key will be _s::siteId => orgId == "_s" and tenantId == siteId
    return { siteId: tenantId };
  } else {
    return { orgId, tenantId, divisionId };
  }
}

/**
 * Returns the "leaf" client id
 * eg:
 * - division then it will be the divisionId
 * - site => siteId
 */
export function targetLeafId(target: IClientTarget | ISiteTarget) {
  return isClientTarget(target)
    ? target.divisionId || target.tenantId || target.orgId
    : target.siteId;
}

export function targetIds(target: IClientTarget | ISiteTarget): string[] {
  return isClientTarget(target)
    ? [target.orgId!, target.tenantId!, target.divisionId!].filter(Boolean)
    : [target.siteId!];
}
