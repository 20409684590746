export function semiRandomId() {
  const time = Date.now();
  const rnd = Math.random();
  return "" + time.toString(36).slice(2) + rnd.toString(36).slice(2);
}

export function nowString() {
  const time = Date.now();
  // const rnd = Math.random();
  return "" + time.toString(36).slice(2); // + rnd.toString(36).slice(2);
}
