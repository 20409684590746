// types
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const SET_SUPERVISOR_FORM: IFieldGroupBuilt = {
  name: "setSupervisor",
  label: "Set Supervisor",
  fields: [
    {
      fieldType: IFieldTypes.userSelectField,
      span: 2,
      name: "supervisorId",
      label: "Supervisor",
    },
  ],
};
