/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  onSnapshot,
  doc,
  getDocs,
  query,
  collection,
  limit,
  where,
  orderBy,
  startAfter,
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  DocumentSnapshot,
} from "firebase/firestore";
import { queryBridge } from "../services/logging";
import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  QueryStatus,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";

// services
import { semiRandomId } from "../utilities/ids";
import { firestore } from "../services/firebase.service";
import { subscriptionQueryClient } from "../contexts/react-query/query-client";
import {
  OrderByType,
  WhereType,
} from "../contexts/subscription/subscription.types";
import { Time } from "wombat-global/src/utilities/time";

/**
 * Add debounce unSub so rapid render unmount doesn't spam firebase sdk
 * - with out this it sub value updates didn't come through after being unsubscribed
 *   (training matrix update after navigating to and from that page didn't come through)
 *
 * - Garbage Collection (GC) timing of this cache must be larger than any of the debounce time
 *   If it not then the __unsubscribe() cant be called before that object is cleaned up
 */
const unsubscribeDebounceMap: Record<string, any> = {};

subscriptionQueryClient.getQueryCache().subscribe(({ type, query: q }) => {
  if (!(q.options as any).enabled) {
    return;
  }

  if (
    type === "observerRemoved" ||
    type === "updated" ||
    type === "added" ||
    type === "observerAdded"
  ) {
    queryBridge.chirp("    QUERY BRIDGE EVENT:", type, q);
  }

  if (
    type === "observerRemoved" &&
    q?.getObserversCount() === 0 &&
    (q as any).__unsubscribe
  ) {
    //
    if (unsubscribeDebounceMap[q.queryKey]) {
      queryBridge.debug("unSub debounced", q.queryKey);
      clearTimeout(unsubscribeDebounceMap[q.queryKey]);
    }

    unsubscribeDebounceMap[q.queryKey] = setTimeout(() => {
      const _q = subscriptionQueryClient
        .getQueryCache()
        .find({ queryKey: q.queryKey, exact: true });

      if (_q && (_q as any).__unsubscribe && _q.getObserversCount() === 0) {
        queryBridge.debug("removing FB sub", _q);
        (_q as any).__unsubscribe();
        // Remove cache item so that following subscribes will initiate correctly
        subscriptionQueryClient.getQueryCache().remove(_q);
      }
    }, Time.seconds * 1);
  }
});

function createQuery<T = DocumentData, B extends DocumentData = DocumentData>(
  getQuery: () => DocumentReference<T, B>, // | Query<T, B>,
): (context: QueryFunctionContext) => Promise<T | null> {
  return async (context: QueryFunctionContext): Promise<T | null> => {
    let firstRun = true;
    let unsubscribe;

    const data = await new Promise((resolve, reject) => {
      const _getRef = getQuery();

      unsubscribe = onSnapshot<T, B>(
        _getRef,
        { includeMetadataChanges: true },
        {
          next: (value) => {
            const tr = "(" + semiRandomId() + ")";
            const cacheHit = value.metadata.fromCache ? "[cache-hit]" : "";

            if (firstRun) {
              queryBridge.log(tr, "first run done", cacheHit, _getRef.path);
              resolve(value.data() || null);
              firstRun = false;
            }

            if (!value.exists()) {
              queryBridge.warn(tr, "doc dne", cacheHit, _getRef.path);
              subscriptionQueryClient.setQueriesData(
                { queryKey: context.queryKey, exact: true },
                () => null,
              );
            } else {
              queryBridge.log(
                tr,
                "doc snap",
                cacheHit,
                value.data(),
                _getRef.path,
              );
              subscriptionQueryClient.setQueriesData(
                { queryKey: context.queryKey, exact: true },
                value.data(),
              );
            }
          },
          error: (error) => {
            queryBridge.error(error);
            reject(error);
          },
          complete: () => {
            queryBridge.warn("Completed callback unused");
          },
        },
      );
    });

    // Get query object from React Query
    const q = subscriptionQueryClient.getQueryCache().find({
      queryKey: context.queryKey,
      exact: true,
    });

    // Remove existing subscription (if there is one) and store new one on `query` object (cleanup)
    if (q && !(q as any).__unsubscribe) {
      (q as any).__unsubscribe = unsubscribe;
    }

    return data as T | null;
  };
}

export type CollectionQueryProps = {
  path: string;
  orderBy?: OrderByType[];
  where?: WhereType[];
  limit?: number;
};
/**
 * getCollection data
 * @param colPath string
 * @param options
 * @returns
 */
export function useCol<T = unknown>(
  args: CollectionQueryProps,
  options: Omit<
    Partial<
      UseInfiniteQueryOptions<
        QuerySnapshot,
        Error,
        InfiniteData<QuerySnapshot<T>>,
        QuerySnapshot,
        QueryKey,
        DocumentSnapshot | undefined
      >
    >,
    "queryKey" | "queryFn" | "getNextPageParams"
  > = {},
) {
  const res = useInfiniteQuery<
    QuerySnapshot,
    Error,
    InfiniteData<QuerySnapshot<T>>,
    QueryKey,
    DocumentSnapshot | undefined
  >({
    ...options,
    initialPageParam: options.initialPageParam || undefined,
    queryKey: [
      "fb-col-query",
      args.path,
      JSON.stringify(args.orderBy),
      JSON.stringify(args.where),
      args.limit,
    ],
    queryFn: ({ pageParam = undefined, ...rest }) => {
      const tr = "(" + semiRandomId() + ")";

      queryBridge.debug(tr, "col", { ...rest, pageParam }, args);
      let req = query(collection(firestore, args.path));

      // each search should be saved in browser memory, so cached
      // thats so navigation to and from a page will not re-query
      // const pathHash = this.encodeKey(key, orderBy, where, startAfter?.id);
      req = query(req, limit(args?.limit || 25));

      if (
        args.where?.length &&
        args.where.some((whr) => operatorsNeedOrderBy.includes(whr.opStr))
      ) {
        const _orderBy = args.where.map((whr) => orderBy(whr.fieldPath));
        queryBridge.debug(tr, "col", "needs orderby", _orderBy);
        // handle one of the firebase limitations
        // "If you include a filter with a range comparison (<, <=, >, >=, not-in),
        // your first ordering must be on the same field"
        // https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
        req = query(req, ..._orderBy);
      }

      if (args.orderBy?.length) {
        const _orderBy = args.orderBy.map((order) =>
          orderBy(order.fieldPath, order.directionStr),
        );
        queryBridge.debug(tr, "col", "orderby", _orderBy);
        req = query(req, ..._orderBy);
      }

      if (args.where?.length) {
        const _where = args.where.map((whr) =>
          where(whr.fieldPath, whr.opStr, whr.value),
        );
        queryBridge.debug(tr, "col", "where", _where);
        req = query(req, ..._where);
      }

      if (pageParam) {
        req = query(req, startAfter(pageParam));
      }

      return getDocs(req).then((d) => {
        if (d.metadata.fromCache) {
          queryBridge.debug(tr, "col [fb cache-hit]", "completed", d);
        } else {
          queryBridge.debug(tr, "col", "completed", d);
        }
        return d;
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage.empty || lastPage.size < (args.limit || 25)
        ? undefined
        : lastPage.docs[lastPage.docs.length - 1];
    },
  });

  if (res.error) {
    queryBridge.error(
      "col",
      args.path,
      args,
      res.error,
      res.errorUpdateCount,
      res.errorUpdatedAt,
    );
  }

  return res;
}

/**
 * getDocument data
 * @param docPath string
 * @param options
 * @returns
 */
export function useDoc(
  docPath: string,
  options: Omit<Partial<UseQueryOptions>, "queryKey" | "queryFn"> = {},
) {
  return useQuery({
    ...options,
    queryKey: ["fb-doc-query", docPath],
    queryFn: () => doc(firestore, docPath),
  });
}

/**
 * Subscribe to document listeners
 */
export function useDocSnap<T>(
  docPath: string,
  options: Omit<Partial<UseQueryOptions>, "queryKey" | "queryFn"> = {},
): [
  value: T | undefined,
  state: QueryStatus,
  errors: unknown | undefined | null,
  unSub: (() => void) | undefined,
] {
  /**
   * Options 2
   * - does not add a lot of chatter though
   * - I don't really understand this one very well.
   */

  const res = useQuery(
    {
      ...options,
      queryKey: ["fb-doc-subscription", docPath],
      queryFn: createQuery(() => doc(firestore, docPath)),
    },
    subscriptionQueryClient,
  );

  return [res.data as T, res.status, res.error, () => {}];
}

export const operatorsNeedOrderBy = ["<", "<=", ">", ">=", "!=", "not-in"];

function mergePages<T = unknown>(acc: T[], page?: QuerySnapshot<T>) {
  if (page?.docs) {
    acc.push(...page.docs.map((d) => d.data()));
  }
  return acc;
}

export function mergeInfiniteQueryData<T>(
  res?: InfiniteData<QuerySnapshot<T>>,
) {
  return res?.pages.reduce(mergePages, [] as T[]) || [];
}
