// libraries
import { Navigate, AsyncElement } from "@tanstack/react-location";
import {
  ADMIN,
  QUERY_TO_DO_USER,
  SUPER_ADMIN,
} from "wombat-global/src/permission.maps";
import { PermissionsClaim } from "wombat-global/src/typings";
import { hasPermission } from "wombat-global/src/utilities/permissions";
import { getAuthorizationTargetsFromClaim } from "../contexts/authentication/getAuthorizationTargetsFromClaim";

// services
import { auth } from "../services/firebase.service";
import { webLinkPath } from "./utilities";

function isPermClaim(claim: unknown): claim is PermissionsClaim {
  if (claim) {
    return true;
  }
  return false;
}

export default function routeElement(
  Component?: React.ReactNode,
): AsyncElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async function innerAsyncFunction(match): Promise<React.ReactNode> {
    // eslint-disable-next-line no-console
    console.time("auth-loading");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,
    while (!(auth as any)["_isInitialized"]) {
      await new Promise((res) => setTimeout(res, 25));
    }
    // eslint-disable-next-line no-console
    console.timeEnd("auth-loading");

    const token = await auth.currentUser?.getIdTokenResult();
    const claims = token?.claims;

    if (!token) {
      return <Navigate to="/login" />;
    }

    if (isPermClaim(claims)) {
      const targetKeys = getAuthorizationTargetsFromClaim(claims);

      if (!targetKeys.length) {
        return <Navigate to="/login" />;
      }

      const { perm, ...target } = targetKeys[0];

      const goToTodo =
        hasPermission(target, claims, ADMIN.bitFlag) ||
        hasPermission(target, claims, SUPER_ADMIN.bitFlag) ||
        hasPermission(target, claims, QUERY_TO_DO_USER.bitFlag);

      if (goToTodo) {
        return <Navigate to={`${webLinkPath(target)}/todo`} />;
      }

      return <Navigate to={webLinkPath(target)} />;
    }

    return (
      Component ?? (
        <div>Looks like you don`t have access to any org or tenant</div>
      )
    );
  };
}
