import { auth } from "../services/firebase.service";

export async function authLoader() {
  // eslint-disable-next-line no-console
  console.time("auth-loading");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,
  while (!(auth as any)["_isInitialized"]) {
    await new Promise((res) => setTimeout(res, 10));
  }
  // eslint-disable-next-line no-console
  console.timeEnd("auth-loading");
}
