// form Definitions
import { descriptionField, systemNameField } from "./field-schema-form";

// types
import { IFieldGroupBuilt, IFieldTypes, ITaskSubTypes } from "../../typings";

export const TaskSettingDefinition: IFieldGroupBuilt = {
  fields: [
    {
      fieldType: IFieldTypes.textField,
      name: "title",
      label: "Form Title",
      required: true,
      span: 2,
    },
    descriptionField,
    {
      fieldType: IFieldTypes.checkbox,
      name: "metadata.correctiveActionFormOnly",
      label: "This Corrective Action is only accessible from within a form?",
      span: 4,
      required: false,
      conditions: [
        {
          parameter: "subType",
          operation: "==",
          value: ITaskSubTypes["corrective-action"],
          action: "visible",
        },
      ],
    },
    {
      fieldType: IFieldTypes.autocomplete,
      freeSolo: true,
      name: "subType",
      label: "Task Type",
      span: 4,
      required: true,
      options: [
        {
          value: ITaskSubTypes["corrective-action"],
          label: "Corrective Action",
        },
        { value: ITaskSubTypes.issue, label: "Issue" },
      ],
    },
    {
      ...systemNameField,
      readonly: true,
      description: "The System name is a unique identifier for the form",
    },
  ],
  min: 1,
  max: 1,
  label: "FieldGroups",
  name: "FieldGroups",
};
