/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

class Logger {
  levelMap = {
    debug: 0,
    log: 1,
    warn: 2,
    error: 3,
    critical: 4,
  };
  minLevel = this.levelMap.warn;
  name = "";
  prefix?: string;
  constructor(name: string, prefix?: string) {
    this.name = name;
    this.prefix = prefix;
  }
  canLog(lvl: number) {
    return (
      lvl >=
      this.levelMap[
        ((window as any)?.__wombat?.logging[this.name]
          ? (window as any)?.__wombat?.logging[this.name].level
          : "warn") as keyof typeof this.levelMap
      ]
    );
  }

  chirp: typeof console.log = (...args) => {
    if (this.canLog(1)) {
      console.log(...args);
    }
  };

  error: typeof console.error = (...args) => {
    if (this.canLog(3)) {
      if (this.prefix) {
        args.unshift(this.prefix + " ERROR:");
      }
      console.error(...args);
    }
  };
  warn: typeof console.warn = (...args) => {
    if (this.canLog(2)) {
      if (this.prefix) {
        args.unshift(this.prefix + ":");
      }

      console.warn(...args);
    }
  };
  log: typeof console.log = (...args) => {
    if (this.canLog(1)) {
      if (this.prefix) {
        args.unshift(this.prefix + ":");
      }

      console.log(...args);
    }
  };
  debug: typeof console.debug = (...args) => {
    if (this.canLog(0)) {
      if (this.prefix) {
        args.unshift(this.prefix + ":");
      }
      console.debug(...args);
    }
  };
}

export const queryBridge = new Logger("queryBridge", "FIRESTORE QUERY BRIDGE");
