import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";

const env = import.meta.env;

import { _firebase } from "./firebase.init";

/**
 * Split out firebase init so that all utilities that require 'window' are in this `.service`, and others are in `.init`
 */
export * from "./firebase.init";

/**
 * analytics setup
 *
 * MEASUREMENT_ID must be set up within the firebase console and then added to the CICD pipeline.
 */
if (env.REACT_APP_MEASUREMENT_ID) {
  getAnalytics(_firebase);
}

// performance will be set up within the firebase console
export const performance = getPerformance(_firebase);

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(_firebase);

// export default _firebase;
