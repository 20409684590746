// routes
import { Routes } from "./router/file-routing";

// contexts
import AllContexts from "./contexts/root";

// components
import ServiceWorkerPrompt from "./components/ServiceWorkerPrompt";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { TermsConditionsModalModal } from "./components/TermsConditionsModal";

const App: React.FC = () => {
  return (
    <AllContexts>
      <Routes />
      <ConfirmationModal />
      <ServiceWorkerPrompt />
      <TermsConditionsModalModal />
    </AllContexts>
  );
};

export default App;
