import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const CONTACT_FORM_FIELD_GROUP: IFieldGroupBuilt = {
  name: "emergencyContactInformation",
  label: "Emergency Contact Information",
  min: 1,
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "name",
      label: "Contact Full Name",
    },
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "type",
      label: "Relationship",
      description:
        "Contact type should be the persons position within the company or a family relation",
    },
    {
      name: "email",
      span: 4,
      label: "Email Address",
      fieldType: IFieldTypes.textField,
      // TODO: create email field
    },
    {
      name: "officePhone",
      span: 1,
      label: "Office Phone",
      fieldType: IFieldTypes.phoneField,
    },
    {
      name: "mobilePhone",
      span: 1,
      label: "Mobile Phone",
      fieldType: IFieldTypes.phoneField,
    },
    {
      name: "homePhone",
      span: 1,
      label: "Home Phone",
      fieldType: IFieldTypes.phoneField,
    },
  ],
};
