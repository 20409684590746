// libraries
import { createRef } from "react";
import {
  SnackbarProvider,
  useSnackbar,
  SnackbarKey,
  OptionsObject,
} from "notistack";

// MUI
import IconButton from "@mui/material/IconButton";
import ButtonIcon from "@mui/icons-material/Close";

// types
type IToastOptions = OptionsObject;
interface IToastContext {
  addToast: (message: string, toastOptions?: IToastOptions) => void;
}

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current && notistackRef.current.closeSnackbar(key);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SnackbarProvider
      ref={notistackRef}
      dense
      preventDuplicate
      maxSnack={5}
      transitionDuration={{ appear: 125, exit: 195 }}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={(key) => (
        <IconButton
          disableRipple
          color="inherit"
          className="pd-0"
          onClick={onClickDismiss(key)}
        >
          <ButtonIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export const useToastContext = (): IToastContext => {
  const { enqueueSnackbar } = useSnackbar();
  const addToast = (message: string, options?: IToastOptions) => {
    enqueueSnackbar(message, options);
  };
  return { addToast };
};
