import { DocumentReference, DocumentData, doc } from "firebase/firestore";

// services
import { firestore } from "../firebase.service";

function getRef(path: string): DocumentReference<DocumentData> {
  return doc(firestore, path);
}

export default getRef;
