// form Definitions
import { descriptionField, systemNameField } from "./field-schema-form";

// types
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const FormSettingDefinition: IFieldGroupBuilt = {
  fields: [
    {
      fieldType: IFieldTypes.textField,
      name: "title",
      label: "Form Title",
      required: true,
      span: 2,
    },
    descriptionField,
    {
      ...systemNameField,
      readonly: true,
      description: "The System name is a unique identifier for the form",
    },
  ],
  min: 1,
  max: 1,
  label: "FieldGroups",
  name: "FieldGroups",
};
