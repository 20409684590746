// libraries
import { createContext, useContext, useMemo, useState } from "react";

// MUI
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// types
type ThemeContextType = {
  setTheme: React.Dispatch<React.SetStateAction<"dark" | "light">>;
  theme: "dark" | "light";
};

type ThemeProviderProps = {
  initialValues: {
    theme: "dark" | "light";
  };
};

export const ThemeContext = createContext<ThemeContextType>({
  setTheme: () => null,
  theme: "light",
});

const inputMuiOverrides = {
  styleOverrides: {
    root: {
      "&.Mui-disabled": {
        WebkitTextFillColor: "var(--color-text--readonly)",
        color: "var(--color-text--readonly)",
      },
    },
    input: {
      "&.Mui-disabled": {
        WebkitTextFillColor: "var(--color-text--readonly)",
        color: "var(--color-text--readonly)",
      },
    },
    label: {
      "&.Mui-disabled": {
        color: "var(--color-text--readonly)",
        WebkitTextFillColor: "var(--color-text--readonly)",
      },
    },
  },
};

export const ThemeProvider: React.FC<
  React.PropsWithChildren<ThemeProviderProps>
> = ({ children, initialValues }) => {
  const [theme, setTheme] = useState(initialValues.theme);

  /*
   * - modifications to ANY MUI components theme should be done globally.
   * - theme colors should be defined here as well as in the index.scss
   */
  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme,
          background: {
            default: theme === "light" ? "#FAFAFC" : "#212121",
            paper: theme === "light" ? "#FFFFFF" : "#161616",
          },
          primary: {
            light: "#53acfc",
            main: "#2898FC",
            dark: "#1C6AB0",
            contrastText: "#FFF",
            // main: theme === "dark" ? "#2898FC" : "#2898FC",
          },
          secondary: {
            light: "#568958",
            main: "#7CC57F",
            dark: "#96d098",
            contrastText: "#FFF",
            // main: theme === "dark" ? "#53ACFC" : "",
          },
          info: {
            light: theme === "light" ? "#e9edfb" : "#01284b",
            main: theme === "light" ? "#172D82" : "#bec9f4",
            dark: theme === "light" ? "#101F5B" : "#172D82",
            contrastText: "#FFF",
          },
          warning: {
            main: "#FDD835",
            light: "#FFFF6B",
            dark: "#C6A700",
            contrastText: "#FFF",
          },
          success: {
            main: "#7ED752",
            light: "#589639",
            dark: "#97DF74",
            contrastText: "#FFF",
          },
          error: {
            main: "#F44336",
            light: "#F88078",
            dark: "#E31B0C",
            contrastText: "#FFF",
          },
          text: {
            primary: theme === "light" ? "#6B7083" : "rgba(255,255,255,0.90)",
            secondary: theme === "light" ? "#1D2A3D" : "rgba(255,255,255,0.90)",
            disabled:
              theme === "light"
                ? "rgba(55, 55, 55, 0.2)"
                : "rgba(255,255,255,0.50)",
          },
          action: {
            active: "rgba(107, 112, 131, 0.85)",
            activatedOpacity: 0.75,
            hover: "rgba(107, 112, 131, 0.08)",
            hoverOpacity: 0.08,
            selected: "rgba(107, 112, 131, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(107, 112, 131, 0.5)",
            disabledBackground: "rgba(107, 112, 131, 0.08)",
            disabledOpacity: 0.5,
            focus: "rgba(107, 112, 131, 0.12)",
            focusOpacity: 0.12,
          },
        },
        shape: {
          borderRadius: 16,
        },
        mixins: {
          toolbar: {
            "minHeight": 56,
            "height": 56,
            "@media (min-width:0px) and (orientation: landscape)": {
              minHeight: 56,
              height: 56,
            },
            "@media (min-width:600px)": {
              minHeight: 64,
              height: 64,
            },
          },
        },
        components: {
          MuiSelect: {
            styleOverrides: {
              outlined: {
                "&:focus": {
                  borderRadius: "inherit",
                },
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                "minWidth": 208,
                "maxWidth": "100%",
                "width": "100%",
                "&.readonly": {
                  "color": "var(--color-text--readonly)",
                  "WebkitTextFillColor": "var(--color-text--readonly)",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--color-text--readonly)",
                  },
                  "label": {
                    color: "var(--color-text--readonly)",
                    WebkitTextFillColor: "var(--color-text--readonly)",
                  },
                },
              },
            },
          },
          MuiFormControlLabel: inputMuiOverrides,
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                "&.Mui-disabled": {
                  ".MuiOutlinedInput-notchedOutline": {
                    opacity: 0.15,
                  },
                  "WebkitTextFillColor": "var(--color-text--readonly)",
                  "color": "var(--color-text--readonly)",
                },
              },
            },
          },
          MuiInputBase: inputMuiOverrides,
          MuiAutocomplete: inputMuiOverrides,
          MuiInputLabel: inputMuiOverrides,
          MuiFormLabel: inputMuiOverrides,
          MuiTab: {
            styleOverrides: {
              root: {
                "textTransform": "capitalize",
                "&:hover": {
                  color: "var(--color-primary)",
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              indicator: {
                height: "4px",
              },
            },
          },
          MuiGrid: {
            styleOverrides: {
              root: {
                ".MuiDataGrid-columnHeader:focus-within": { outline: "none" },
                ".MuiDataGrid-columnHeader:focus": { outline: "none" },
                ".MuiDataGrid-columnHeader:select": { outline: "none" },
                ".MuiDataGrid-cell:focus-within": { outline: "none" },
                ".MuiDataGrid-cell:focus": { outline: "none" },
                ".MuiDataGrid-cell:select": { outline: "none" },
                ".MuiDataGrid-footerContainer": { display: "none" },
                ".MuiDataGrid-columnSeparator": { display: "none" },
              },
            },
          },
          MuiButton: {
            defaultProps: {
              variant: "outlined",
              disableElevation: true,
            },
            styleOverrides: {
              root: {
                textTransform: "unset",
                borderRadius: "500px",
                paddingRight: "24px",
                paddingLeft: "24px",
              },
              outlined: {
                textTransform: "unset",
              },
            },
          },
        },
        typography: {
          h1: {
            fontSize: 36,
            fontWeight: 600,
            lineHeight: -1.5,
            fontStyle: "normal",
            letterSpacing: -1,
          },
          h2: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: -0.5,
          },
          h3: {
            fontSize: 18,
            lineHeight: "24px",
            fontWeight: 600,
          },
          h4: {
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 500,
          },
          h5: {
            fontSize: 24,
            fontWeight: 500,
          },
          h6: {
            fontSize: 20,
            fontWeight: 500,
          },
          body1: {
            fontSize: 16,
          },
          body2: {
            fontSize: 14,
          },
          fontSize: 14,
          fontFamily: `
            'Poppins', 
            -apple-system, 
            BlinkMacSystemFont, 
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue', 
            sans-serif`,
        },
      }),
    [theme],
  );

  /**
   * Memoize provider value to reduce likely hood of re-render bug
   */
  const memoizedThemeProviderValue = useMemo(
    () => ({
      setTheme,
      theme,
    }),
    [setTheme, theme],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={memoizedThemeProviderValue}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export const useThemeContext = (): ThemeContextType => {
  return useContext(ThemeContext);
};
