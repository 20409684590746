// forms
import { ADDRESS_FORM_FIELDS } from "./address-form";
import { CONTACT_INFORMATION_FORM_GROUP } from "./user-contact-info";

// types
import {
  FieldGroupOrderingTypes,
  IFieldGroupBuilt,
  IFieldTypes,
} from "../../typings";

export const EDIT_USER_FORM: IFieldGroupBuilt = {
  name: "basicInformation",
  label: "Basic Information",
  ordering: [
    {
      type: FieldGroupOrderingTypes.group,
      name: "user",
      orderIdx: 0,
      entryIdx: 0,
    },
    {
      type: FieldGroupOrderingTypes.group,
      name: "user.metadata.address",
      orderIdx: 1,
      entryIdx: 1,
    },
    {
      type: FieldGroupOrderingTypes.group,
      name: "user.metadata.userContactInformation",
      orderIdx: 2,
      entryIdx: 2,
    },
  ],
  groups: [
    {
      fields: [
        {
          fieldType: IFieldTypes.emailField,
          span: 2,
          name: "email",
          label: "Email",
          required: true,
          conditions: [
            {
              parameter: "[[linkedAuthentication]]",
              operation: "!=",
              value: true,
              action: "readonly",
            },
            {
              parameter: "[[isAdmin]]",
              operation: "!=",
              value: true,
              action: "readonly",
            },
          ],
          description:
            "The email address the user will login with. It may not be changeable if it's managed outside of your organization.",
        },
        {
          fieldType: IFieldTypes.checkbox,
          name: "linkedAuthentication",
          label: "Linked Authentication",
          hidden: true,
          span: 1,
        },
        {
          fieldType: IFieldTypes.textField,
          name: "metadata.firstName",
          span: 1,
          label: "First Name",
          required: true,
        },
        {
          span: 1,
          fieldType: IFieldTypes.textField,
          name: "metadata.middleName",
          label: "Middle Name",
        },
        {
          span: 1,
          fieldType: IFieldTypes.textField,
          name: "metadata.lastName",
          label: "Last Name",
          required: true,
        },
        {
          fieldType: IFieldTypes.textField,
          span: 2,
          name: "displayName",
          label: "Display Name",
        },
        {
          fieldType: IFieldTypes.select,
          span: 2,
          readonly: true,
          name: "primaryRoleId",
          label: "Primary Job Title",
          options: [],
        },
        {
          fieldType: IFieldTypes.textField,
          span: 2,
          name: "metadata.employeeId",
          label: "Employee Number",
          conditions: [
            {
              parameter: "[[isAdmin]]",
              operation: "!=",
              value: true,
              action: "readonly",
            },
          ],
        },
        {
          fieldType: IFieldTypes.dateField,
          span: 2,
          name: "metadata.hireDate",
          label: "Hired Date",
          outputNumber: true,
          conditions: [
            {
              parameter: "[[isAdmin]]",
              operation: "!=",
              value: true,
              action: "readonly",
            },
          ],
        },
        // {
        //   name: "metadata.employmentStatus",
        //   label: "Employment Status",
        //   span: 2,
        //   required: true,
        //   defaultValue: EntityStatus.active,
        //   fieldType: IFieldTypes.select,
        //   options: [
        //     {
        //       label: "Inactive Employee",
        //       value: EntityStatus.inactive,
        //     },
        //     {
        //       label: "Active Employee",
        //       value: EntityStatus.active,
        //     },
        //   ],
        // },
      ],
      min: 1,
      max: 1,
      label: "Basic Information",
      name: "user",
      isSection: true,
    },
    {
      ...ADDRESS_FORM_FIELDS,
      min: 1,
      max: 1,
      label: "Home Address",
      name: "user.metadata.address",
    },
    {
      ...CONTACT_INFORMATION_FORM_GROUP,
      name: "user.metadata.userContactInformation",
      label: "Contact Information",
      min: 1,
      max: 1,
    },
  ],
};
