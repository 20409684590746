import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const CREATE_DASHBOARD_FORM: IFieldGroupBuilt = {
  fields: [
    {
      fieldType: IFieldTypes.textField,
      autoFocus: true,
      name: "title",
      label: "Title",
      required: true,
      span: 2,
    },
    {
      fieldType: IFieldTypes.textArea,
      name: "description",
      label: "Description",
      max: 2500,
      defaultValue: "",
      span: 4,
    },
  ],
  min: 1,
  max: 1,
  label: "dashboard-creation",
  name: "DashboardCreation",
};
