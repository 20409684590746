/* eslint-disable no-console */
/**
 * API for turning on and off log levels within the browser
 *
 * Integrations:
 * - logging module
 */

(function () {
  if (window && !window.__wombat)
    window.__wombat = {
      logging: {
        queryBridge: {
          level: localStorage.getItem("__wombat.queryBridge.level") || "warn",
          /**
           * @param {"warn" | "debug" | "log"} key
           */
          enable(key) {
            if (!["warn", "log", "debug"].includes(key)) {
              console.warn("Log level must be on of ", [
                "warn",
                "log",
                "debug",
              ]);
              return;
            }
            this.level = key;
            localStorage.setItem("__wombat.queryBridge.level", key);
            console.log("Query Bridge: logging level set to '", key);
          },
        },
      },
    };
})();
