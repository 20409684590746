/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ADMIN } from "../permission.maps";

// types
import { IClientTarget, ISiteTarget } from "../typings/generics";
import { PermissionsClaim } from "../typings/permissions";

export function hasWombatStaffPermissions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any,
  bitFlag: number,
): boolean {
  if (!claim) {
    return false;
  }
  return claim.perm && (claim.perm._p & bitFlag) == bitFlag;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasWombatStaffAdminPermissions(claim: any): boolean {
  if (!claim) {
    return false;
  }
  return claim.perm && (claim.perm._p & ADMIN.bitFlag) == ADMIN.bitFlag;
}

/**
 * Notes:
 * - tests are for this are ran within the api test suite
 */
export const hasAdminPermissions = (
  target: IClientTarget | ISiteTarget,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any,
): boolean => {
  const {
    orgId: o,
    tenantId: t,
    divisionId: d,
    siteId: s,
  } = target as unknown as Record<string, string>;

  if (!claim) {
    return false;
  }

  const hasSiteClaim = claim.prem && claim.perm[s];
  if (hasSiteClaim) {
    return (claim.perm[siteKey(s)]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }

  const hasOrgClaim = claim.perm && claim.perm[o];
  const hasTenantClaim = hasOrgClaim && t && claim.perm[o][t];
  const hasDivisionClaim = hasTenantClaim && t && d && claim.perm[o][t][d];

  // permission target is to the org
  if (hasOrgClaim) {
    return (claim.perm[o]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }

  // permission target is to the tenant
  if (hasTenantClaim && t) {
    return (claim.perm[o][t]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }

  // permission target is to the division
  if (hasDivisionClaim && t && d) {
    return (claim.perm[o][t][d]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }
  return false;
};

function siteKey(siteId: string) {
  return "s_" + siteId;
}
export const sitePermissionKey = siteKey;

export const getCurrentBitFlag = (
  target: IClientTarget | ISiteTarget,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any,
): number => {
  const {
    orgId: o,
    tenantId: t,
    divisionId: d,
    siteId: s,
  } = target as unknown as Record<string, string>;

  if (claim.perm && claim.perm[siteKey(s)]) {
    return claim.perm[siteKey(s)]?._p || 0;
  }

  const hasOrgClaim = claim.perm && claim.perm[o];
  const hasTenantClaim = hasOrgClaim && t && claim.perm[o][t];
  const hasDivisionClaim = hasTenantClaim && t && d && claim.perm[o][t][d];

  // permission target is to the org
  if (hasOrgClaim && !t) {
    return claim.perm[o]?._p || 0;
  }

  // permission target is to the tenant
  if (hasTenantClaim && t && !d) {
    return claim.perm[o][t]?._p || 0;
  }

  // permission target is to the division
  if (hasDivisionClaim && t && d) {
    return claim.perm[o][t][d]?._p || 0;
  }

  return 0;
};

export const hasPermission = (
  target: IClientTarget | ISiteTarget | undefined,
  permissionClaim: PermissionsClaim,
  bitFlag: number,
): boolean => {
  // check for staff access
  if (hasWombatStaffAdminPermissions(permissionClaim)) {
    return true;
  }

  if (!target) {
    return false;
  }

  // if any of the targets parents are admin then the user has permissions
  if (hasAdminPermissions(target, permissionClaim)) {
    return true;
  }

  const userPermissionFlag = getCurrentBitFlag(target, permissionClaim);
  if (!userPermissionFlag) {
    return false;
  }
  return (userPermissionFlag & bitFlag) === bitFlag;
};

export const getTenantKeysFromPermissionClaim = (
  orgId: string,
  permissionClaim: PermissionsClaim,
): string[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const perm = permissionClaim.perm as any;
  const orgPerms = perm[orgId];
  if (orgPerms) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _p, ...tenantPermissionsMap } = orgPerms;
    return Object.keys(tenantPermissionsMap || {});
  }
  return [];
};
