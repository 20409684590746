import { useEffect } from "react";

// contexts
import { AuthenticationProvider } from "./authentication/authentication.context";
import { ReactQueryProvider } from "./react-query/react-query.context";
import { DataStoreProvider, emptyStoreState } from "./dataStore.context";
import { ThemeProvider } from "./theme.provider";
import { ToastProvider } from "./toast.context";
import { ConnectivityStatusProvider } from "./connectivity.context";

/*
 * HOC for cleaning up Context Provider imports
 */
const AllContexts: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isDark =
    localStorage.getItem("theme") === "dark" &&
    window.frameElement?.id !== "formPdf";
  const theme =
    isDark && window.frameElement?.id !== "formPdf" ? "dark" : "light";

  useEffect(() => {
    if (!isDark) {
      document.querySelector("html")?.classList.remove("dark-theme");
    } else {
      document.querySelector("html")?.classList.add("dark-theme");
    }
  }, [isDark]);

  return (
    <ReactQueryProvider>
      <ConnectivityStatusProvider>
        <DataStoreProvider
          initialData={JSON.parse(JSON.stringify(emptyStoreState))}
        >
          <ThemeProvider
            initialValues={{
              theme: theme,
            }}
          >
            <ToastProvider>
              <AuthenticationProvider>{children}</AuthenticationProvider>
            </ToastProvider>
          </ThemeProvider>
        </DataStoreProvider>
      </ConnectivityStatusProvider>
    </ReactQueryProvider>
  );
};

export default AllContexts;
