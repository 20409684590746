import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const FORM_CREATION_FORM: IFieldGroupBuilt = {
  fields: [
    {
      fieldType: IFieldTypes.textField,
      autoFocus: true,
      name: "title",
      label: "Title",
      required: true,
      span: 2,
    },
    {
      fieldType: IFieldTypes.textField,
      autoFocus: false,
      name: "name",
      label: "System Name",
      required: true,
      span: 2,
    },
    {
      fieldType: IFieldTypes.textArea,
      name: "description",
      label: "Description",
      max: 2500,
      defaultValue: "",
      span: 4,
    },
  ],
  min: 1,
  max: 1,
  label: "form-creation",
  name: "FormCreation",
};
