import type {
  RouteMatch,
  DefaultGenerics,
  PartialGenerics,
} from "@tanstack/react-location";
import {
  DivisionEntity,
  IClientTarget,
  IObjectInstance,
  ISiteTarget,
  OrganizationEntity,
  ShareConfig,
  SiteEntity,
  TenantEntity,
} from "wombat-global/src/typings";

export type PathConfig = {
  private?: boolean;
  // any of the permission bits in the array will satisfy access
  permission?: number | number[];
  feature?: number;
};

export const defaultPageConfig = {
  private: true,
};

export interface TanUseMatch<
  Params extends { [key: string]: string } = Record<string, string>,
  Data = unknown,
> extends PartialGenerics {
  LoaderData: {
    orgData?: OrganizationEntity;
    tenantData?: TenantEntity;
    divisionData?: DivisionEntity;
    siteData?: SiteEntity;
    formData?: IObjectInstance;
    share?: { config: ShareConfig };
  } & Data;
  Params: Params;
}

export interface ShareUseMatch<Params = unknown, Data = unknown>
  extends PartialGenerics {
  LoaderData: Record<string, unknown> & Data;
  Params: { orgId: string; tenantId?: string } & Params;
}

export type LoaderArgs = RouteMatch<DefaultGenerics>;
