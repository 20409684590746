/* eslint-disable max-len */
export const TERMS_CONDITIONS = {
  version: "1",
  content: `<div class="WordSection2"><p class="MsoBodyText" align="left" style="text-align:left"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial;color:red;display:none;
mso-hide:all">The following numbering is done with the Alt NB numbering
macro.<span style="mso-spacerun:yes">&nbsp; </span>There are 6 levels (Heading 1 to
Heading 6 styles); shortcut keys Ctrl Alt 1 to Ctrl Alt 6.<span style="mso-spacerun:yes">&nbsp; </span><o:p></o:p></span></b></p>

<h1 style="mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">GOVERNING
TERMS<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span><o:p></o:p></span></h1>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">These SaaS Subscription Agreement Terms of Service govern your (“<b style="mso-bidi-font-weight:normal">you</b>” or “<b style="mso-bidi-font-weight:
normal">Customer</b>”) use of the hosted platform-as-a-service and applications
provided by Wombat Software Inc. (“<b style="mso-bidi-font-weight:normal">Wombat</b>”),
and includes any updates made available by Wombat from time to time
(collectively, the “<b style="mso-bidi-font-weight:normal">Services</b>”).<span style="mso-spacerun:yes">&nbsp; </span>Please read these Terms of Service carefully,
as they constitute a legally binding contract between you and Wombat. <span style="mso-spacerun:yes">&nbsp;</span>If you do not agree to these Terms of Service,
you may not access or use the Services.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">These Terms of Service were last updated on March 30, 2023. <span style="mso-spacerun:yes">&nbsp;</span>At any time and for any reason, we may update,
change, suspend or terminate any aspect of these Terms or the Services.<span style="mso-spacerun:yes">&nbsp; </span>We will endeavour to post advance notice of
any changes to this site.<span style="mso-spacerun:yes">&nbsp; </span>Please consult
these Terms of Service each time that you use the Services. <span style="mso-spacerun:yes">&nbsp;</span>Your continued use of the Services for 30 days
or more following the posting of changes to these Terms of Service or the
implementation of any change to the Services will mean that you accept and
agree to those changes.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial">IF YOU ARE GRANTED THE RIGHT
TO USE THE SERVICES FOR A FREE TRIAL OR FOR DEMONSTRATION PURPOSES, THESE TERMS
OF SERVICE GOVERN THAT USE, PROVIDED THAT: (I) ANY TERMS REFERENCING WARRANTIES
SHALL NOT APPLY TO SUCH USE AND, IN ALL CASES, THE SERVICES ARE PROVIDED ON AN
“AS IS” BASIS;<span style="mso-spacerun:yes">&nbsp; </span>(II) ANY SUCH USE IS AT
THE CUSTOMER’S OWN RISK, AND WOMBAT DISCLAIMS ANY LIABILITY IN RESPECT THEREOF;
(III) CUSTOMER MAY USE THE SERVICES ONLY FOR TRIAL OR DEMONSTRATION PURPOSES
DURING THE PROSCRIBED TRIAL OR DEMONSTRATION PERIOD, AND ANY REVENUE-GENERATING
USE IS EXPRESLLY PROHIBITED; AND (IV) WOMBAT RESERVES THE RIGHT TO TERMINATE
THE FREE TRIAL OR DEMONSTRATION AT ANY TIME.<o:p></o:p></span></b></p>

<h1 style="mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">DEFINITIONS
<o:p></o:p></span></h1>

<p class="MsoBodyText" style="margin-top:0cm"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial">Definitions. <span style="mso-spacerun:yes">&nbsp;</span></span></b><span style="mso-bidi-font-family:
Arial">Capitalized terms used in these Terms of Service and not otherwise
defined have the following meanings:<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial">“Accepted Order” </span></b><span style="mso-bidi-font-family:Arial">means a <span class="GramE">Customer</span>
order for Services that has been accepted by Wombat.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial">“Agreement”</span></b><span style="mso-bidi-font-family:Arial"> means these Terms of Service, including the
details of the Services purchased as agreed in an Accepted Order.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">Authorized User</b>”<b style="mso-bidi-font-weight:normal"> </b>means a <span class="GramE">Customer</span>
employee or individual contractor with a user account who is authorized by
Customer to access and use the Services. <o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">Confidential Information</b>”<b style="mso-bidi-font-weight:normal"> </b>has the meaning ascribed to it in
Section 9.1.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">Customer Data</b>”<b style="mso-bidi-font-weight:normal"> </b>means any information (including
Personal Information), data, communications or other content uploaded,
generated, <span class="GramE">stored</span> or transmitted by Customer and/or
Authorized Users as part of, or in conjunction with the use of the<b style="mso-bidi-font-weight:normal"> </b>Services.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">Documentation</b>”<b style="mso-bidi-font-weight:normal"> </b>means the documentation provided by Wombat
to Customer in connection with the Services.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">Initial Term</b>”<b style="mso-bidi-font-weight:normal"> </b>means the initial subscription period
for the Services as set out in the Accepted Order.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">IP Rights</b>”<b style="mso-bidi-font-weight:normal"> </b>means any right that is granted or
recognized under any Canadian, United States, European or foreign legislation
regarding patents, copyrights, neighbouring rights, moral rights, trademarks
(including trade names and service marks), trade secrets, industrial designs,
design rights, mask work, integrated circuit topography, privacy and publicity
rights and any other statutory provision or common or civil law principle
regarding intellectual and industrial property, whether registered or unregistered,
and including rights in any application for any of the foregoing.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">Personal Information</b>”<b style="mso-bidi-font-weight:normal"> </b>means information about an
identifiable individual.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial">“<b style="mso-bidi-font-weight:normal">System</b>”<b style="mso-bidi-font-weight:
normal"> </b>means the proprietary and third-party information technology
systems and environments used by Wombat to provide the Services.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial">“Term”</span></b><span style="mso-bidi-font-family:Arial"> means the Initial Term and all renewal
terms (if any).<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><span style="mso-bidi-font-family:
Arial"><o:p>&nbsp;</o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><b style="mso-bidi-font-weight:
normal"><span style="mso-bidi-font-family:Arial">“Wombat Trademarks” </span></b><span style="mso-bidi-font-family:Arial">means the registered and unregistered
trademarks, trade names, logos or service marks of Wombat as used by Wombat in
connection with the Services from time to time. <o:p></o:p></span></p>

<h1 style="mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">LICENSED
SERVICES<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">3.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Use of the Services</span></b><span style="mso-bidi-font-family:Arial">. Customer’s access to and use of the Services
is on a subscription basis during the Term. <span style="mso-spacerun:yes">&nbsp;</span>Subject to the terms and conditions of the
Agreement, including without limitation the restrictions in Section 3.2, Wombat
grants to Customer a non-exclusive, non-assignable, non-transferable limited
license to access and use the Services solely for Customer’s own internal
business purposes, and to grant each Authorized User the limited right to use
the Services solely as necessary for the exercise by Customer of its rights
hereunder.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">3.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Restrictions on Use</span></b><span style="mso-bidi-font-family:Arial">. Customer shall not, and shall not permit
an Authorized User or any other person to, directly or indirectly do any of the
following: (a) reverse engineer, decompile, disassemble or otherwise attempt to
discover the source code or underlying algorithms of the Services (or any part
thereof) and/or the System; (b) modify, translate, or create derivative works
based on any portion of the Services or Documentation; (c) rent, lease,
distribute, license, sublicense, sell, resell, assign, or otherwise
commercially exploit any portion of the Services and/or the System or make any
portion of the Services available to any person other than Authorized Users; (d)
use any part of the Services for timesharing or service bureau purposes, or
otherwise make the Services available for use by or for the benefit of third
parties, including Customer affiliates; (e) publish or disclose to any person evaluations
of the Services; (f) tamper with the security of any portion of the Services
and/or the System; (g) knowingly access data on or available through the Services
and/or the System not intended for Customer or Authorized Users; (h) attempt to
probe, scan or test the vulnerability of any portion of the System or to breach
the security or authentication measures without Wombat’s prior written consen<s>t</s>;
(<span class="SpellE">i</span>) use the Services to violate any applicable local,
provincial, state, federal or foreign law, rule or regulation; or (j) create,
solicit, transmit, upload or publish any comment, request, suggestion, proposal,
image, data file or other communication through the Services which does or may:
(<span class="SpellE">i</span>) violate the rights of others, including any
privacy or IP Rights; (ii) contain or embody any computer virus, harmful
component or corrupted data; (iii) adversely affect the performance or
availability of any of the Services or the System; or (iv) be used to
impersonate any person, including Wombat personnel.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">3.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Authorized Users and Accounts</span></b><span style="mso-bidi-font-family:Arial">.<b style="mso-bidi-font-weight:normal"><o:p></o:p></b></span></p>

<p style="margin-top:0cm;mso-list:none"><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">3</span></b><span style="mso-bidi-font-family:
Arial">.<b style="mso-bidi-font-weight:normal">3.1</b><span style="mso-tab-count:
1">&nbsp;&nbsp;&nbsp;&nbsp; </span>If a specified number of Authorized Users is set out in the
Accepted Order, Customer’s access to and use of the Services is restricted to
the specified number of Authorized Users. <span style="mso-spacerun:yes">&nbsp;</span>Customer may add Authorized Users at any time,
in accordance with the fee structure set out in the Accepted Order. Each
Authorized User will be entitled to access the Services by means of a user
account (“<b style="mso-bidi-font-weight:normal">Account</b>”) using a unique
username and password.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin-top:0cm;mso-list:none"><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">3.3.2<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span style="mso-bidi-font-family:Arial">Customer must purchase at least one
administrator Account for accessing the Services. <span style="mso-spacerun:yes">&nbsp;</span>Through the administrator Account, Customer
will be able to establish additional Accounts for Authorized Users. <span style="mso-spacerun:yes">&nbsp;</span>Customer is responsible for authorizing and
controlling access to the Services by Authorized Users. <span style="mso-spacerun:yes">&nbsp;</span>Customer agrees to notify Wombat immediately
upon becoming aware of any breach of security or unauthorized use of any
Accounts (including the administrator Account).<b style="mso-bidi-font-weight:
normal"><o:p></o:p></b></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">3.4<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Additional Customer Responsibilities</span></b><span style="mso-bidi-font-family:Arial">. Customer is solely responsible for
obtaining and maintaining all equipment, computers, networks, <span class="GramE">systems</span> and communications required to access the Services,
and for all expenses related thereto. <span style="mso-spacerun:yes">&nbsp;</span>Customer
is responsible for complying with, and causing Authorized Users to comply, with
these Terms of Service. <span style="mso-spacerun:yes">&nbsp;</span>Customer will
notify Wombat immediately of any actual or suspected unauthorized use of Services
or any Accounts or other breach of security in relation to the Services,
Customer Data or the System of which Customer becomes aware.<o:p></o:p></span></p>

<h1 style="mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">CUSTOMER
DATA / PRIVACY<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">4.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Monitoring</span></b><span style="mso-bidi-font-family:
Arial">. Wombat has no obligation to monitor or pre-screen any Customer Data. <span style="mso-spacerun:yes">&nbsp;</span>Customer shall <span class="GramE">be solely
responsible at all times</span> for monitoring Customer Data and for ensuring
that all Customer Data complies with these Terms of Service. <span style="mso-spacerun:yes">&nbsp;</span>Customer is solely responsible for the
accuracy, quality, integrity and legality of Customer Data and <span class="GramE">the means by which</span> Customer acquires Customer Data.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">4.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Rights to Customer Data</span></b><span style="mso-bidi-font-family:Arial">. As between Wombat and Customer, Customer
retains all rights, including IP Rights, in and to all Customer Data. <span style="mso-spacerun:yes">&nbsp;</span>Customer hereby grants to Wombat a limited
license to access and use Customer Data solely as necessary for the purpose of
providing the Services and related services to Customer, which includes making
Customer Data available to Authorized Users. <span style="mso-spacerun:yes">&nbsp;</span>All rights in Customer Data held by Customer
and not granted to Wombat are reserved to Customer. <span style="mso-spacerun:yes">&nbsp;</span>Customer represents and warrants that Customer
is the owner or authorized licensee of all rights in and to all Customer Data,
or otherwise has the rights to grant the license set forth in this Section 4.2.
<span style="mso-spacerun:yes">&nbsp;</span>The foregoing representation and
warranty shall survive the expiration or termination of the Agreement.<o:p></o:p></span></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:none"><span style="mso-bidi-font-family:Arial"><span style="mso-spacerun:yes">&nbsp; </span><o:p></o:p></span></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">4.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Personal Information</span></b><span style="mso-bidi-font-family:Arial">. If Customer discloses any Personal
Information to Wombat or transfers Personal Information to Wombat for
processing, Customer is deemed to represent, warrant and covenant to Wombat
that: (a) Customer is solely and exclusively responsible for the collection,
accuracy or completeness of Personal Information provided to Wombat; and (b)
all such Personal Information provided to Wombat has been or will be collected
and disclosed in accordance with all applicable laws. <span style="mso-spacerun:yes">&nbsp;</span>Customer hereby grants to Wombat the limited
right to collect, store, access and use the Personal Information solely for
purposes of providing the Services to Customer. <span style="mso-spacerun:yes">&nbsp;</span>Customer represents and warrants that it has
obtained and will continue to obtain all necessary consents from all
individuals whose Personal Information is disclosed or transferred to Wombat
pursuant to this Section 4.3.<span style="mso-spacerun:yes">&nbsp; </span>Wombat
agrees to use any Personal Information only for the purposes for which it was
disclosed, and in accordance with applicable Canadian and British Columbia privacy
legislation.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">INTELLECTUAL
PROPERTY RIGHTS<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">5.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Ownership</span></b><span style="mso-bidi-font-family:
Arial">. The Services and Documentation (including any content therein that is
not Customer Data), all copies and portions thereof, Wombat Trademarks and all
improvements, enhancements, <span class="GramE">modifications</span> and
derivative works thereof (excluding all Customer Data), and all IP Rights
therein (collectively, “<b style="mso-bidi-font-weight:normal">Wombat Property</b>”),
are and shall remain the sole and exclusive property of Wombat and are
protected by domestic and international laws and treaties. <span style="mso-spacerun:yes">&nbsp;</span>Customer agrees not to, and not to cause or
permit others to: (a) remove any proprietary notices, markings and legends
appearing on or contained in or on any Wombat Property; or (b) change any
security or rights management technology used in connection with any Wombat
Property.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">5.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Rights Reserved <span class="GramE">By</span> Wombat</span></b><span style="mso-bidi-font-family:Arial">. Customer’s rights to use the Services and
other Wombat Property shall be limited to those expressly granted under these
Terms of Service. No other rights with respect to the Services or any other Wombat
Property (including all related IP Rights) are implied. <span style="mso-spacerun:yes">&nbsp;</span>Customer agrees that Customer will use all
commercially reasonable measures to protect Wombat’s proprietary interests and
IP Rights in Wombat <span class="GramE">Property, and</span> will comply with these
Terms of Service to protect Wombat’s proprietary and IP Rights in Wombat
Property. <span style="mso-spacerun:yes">&nbsp;</span>Except as Wombat may otherwise
expressly agree in writing, any discoveries, enhancements, improvements,
customizations, <span class="GramE">translations</span> or other modifications
made to, or derived from, Wombat Property, and all related IP Rights therein,
shall be owned exclusively by Wombat.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">5.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Trademarks</span></b><span style="mso-bidi-font-family:
Arial">. Customer recognizes and acknowledges the great value of the goodwill
associated with Wombat’s name and trademarks, and the identification of Wombat’s
software and services therewith.<span style="mso-spacerun:yes">&nbsp;
</span>Customer agrees that it obtains no rights, <span class="GramE">title</span>
or interest of any kind in or to any trademarks, tradenames, logos, service
marks or other markings belonging to Wombat.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">5.4<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Feedback</span></b><span style="mso-bidi-font-family:
Arial">. Any suggestions, enhancement requests, recommendations or other
feedback provided by Customer to Wombat relating to the Services (collectively
“<b style="mso-bidi-font-weight:normal">Feedback</b>”) shall become Wombat’s
sole property. <span style="mso-spacerun:yes">&nbsp;</span>Wombat shall exclusively
own all rights, including all IP Rights, in and to all Feedback.<span style="mso-spacerun:yes">&nbsp; </span>Wombat shall be entitled to the unrestricted
use and dissemination of any Feedback for any purpose, commercial or otherwise,
without acknowledgement, <span class="GramE">attribution</span> or compensation
to Customer.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">6.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">FEES
AND PAYMENT<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">6.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Fees</span></b><span style="mso-bidi-font-family:
Arial">. Subscription fees payable by Customer to Wombat or a reseller for the Services
are set out in the relevant Accepted Order and are payable in advance. <span style="mso-spacerun:yes">&nbsp;</span>Except as otherwise specified in the
Agreement, payment obligations are non-<span class="GramE">cancellable</span> and
all fees are non-refundable. <span style="mso-spacerun:yes">&nbsp;</span>Subscription
fees may be included in the bundled amounts paid by Customer to a reseller,
rather than paid directly by Customer to Wombat.<b style="mso-bidi-font-weight:
normal"><o:p></o:p></b></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">6.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Taxes</span></b><span style="mso-bidi-font-family:
Arial">. All fees are net of any sales, use, value added and similar taxes, as
well as any duties and levies, imposed by any governmental authority (“<b style="mso-bidi-font-weight:normal">Taxes</b>”), all of which shall be the
responsibility of Customer, but excluding taxes on Wombat’s income. <span style="mso-spacerun:yes">&nbsp;</span>Customer shall pay all such Taxes or provide Wombat
with a tax or levy exemption certificate acceptable to the taxing authority.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">6.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Payment Terms</span></b><span style="mso-bidi-font-family:Arial">. Any undisputed amounts owed by Customer to
Wombat for the Services that are not paid within thirty (30) days of the
invoice due date, shall accrue interest at the lesser of two percent (2%) per
month (twenty-four percent (24%) per annum) and the maximum rate permitted by
law, calculated from the date of the invoice. <span style="mso-spacerun:yes">&nbsp;</span>If any undisputed amount owing by Customer
under the Agreement is thirty (30) or more days overdue Wombat may, upon notice
to Customer and without limiting Wombat’s other rights and remedies, suspend
Customer’s (including Authorized Users’) access to the Services. <span style="mso-spacerun:yes">&nbsp;</span>Customer will continue to be charged and
remain liable for the applicable fees and other charges for all Services during
any suspension period.<span style="mso-spacerun:yes">&nbsp; </span>If any undisputed
amount is more than ninety (90) days overdue, Wombat will, in addition to its
other rights and remedies be entitled to terminate the Agreement.<b style="mso-bidi-font-weight:normal"><o:p></o:p></b></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">7.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">NO
WARRANTY<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">7.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><span style="mso-bidi-font-family:Arial">TO
THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED “AS
IS”. <span style="mso-spacerun:yes">&nbsp;</span><span style="text-transform:uppercase">Wombat</span>
DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS
OR IMPLIED, INCLUDING, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
FITNESS FOR USE, FITNESS FOR A PARTICULAR PURPOSE OR THOSE ARISING BY LAW,
STATUTE, USAGE OR TRADE, OR COURSE OF DEALING REGARDING OR RELATED TO THE
AGREEMENT, THE SERVICES, THE DOCUMENTATION, OR ANY MATERIALS OR SERVICES
FURNISHED OR PROVIDED TO CUSTOMER UNDER THE AGREEMENT. <span style="mso-spacerun:yes">&nbsp;</span><span style="text-transform:uppercase">Wombat</span>
DOES NOT WARRANT THAT THE SERVICES WILL BE ERROR FREE, WILL OPERATE WITHOUT
INTERRUPTION OR THAT THEY WILL SATISFY CUSTOMER’S REQUIREMENTS OR PRODUCE ANY
SPECIFIC RESULTS. <span style="mso-spacerun:yes">&nbsp;</span>EXCEPT FOR WOMBAT’S
OBLIGATIONS THAT ARE EXPRESSLY SET FORTH IN THE AGREEMENT, THE ENTIRE RISK
ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICES REMAINS WITH CUSTOMER. <span style="mso-spacerun:yes">&nbsp;</span>WOMBAT DOES NOT WARRANT THE ACCURACY OR
SECURITY OF ANY CUSTOMER DATA, INCLUDING ANY DATA GENERATED THROUGH THE USE OF
THE SERVICES.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">8.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">LIMITATION
OF LIABILITY<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">8.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><span style="mso-bidi-font-family:Arial">IN
NO EVENT SHALL WOMBAT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL,
EXEMPLARY OR PUNITIVE DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF DATA,
LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS
INFORMATION) ARISING OUT OF OR RELATED TO THE SERVICES OR THEIR USE, HOWEVER
CAUSED, INCLUDING UNDER ANY THEORY OF LIABILITY, WHETHER BASED IN CONTRACT,
TORT, NEGLIGENCE OR OTHERWISE, EVEN IF WOMBAT HAS BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES. <span style="mso-spacerun:yes">&nbsp;</span>WOMBAT’S,
ITS DIRECTORS’, <span class="GramE">OFFICERS’</span> AND EMPLOYEES’ TOTAL
AGGREGATE LIABILITY TO CUSTOMER ARISING OUT OF OR RELATING TO THIS AGREEMENT
SHALL NOT EXCEED THE AMOUNT ACTUALLY PAID TO WOMBAT IN RESPECT OF CUSTOMER’S
USE OF THE SERVICES DURING THE SIX (6) MONTH PERIOD PRECEDING THE INITIATION OF
A CLAIM BY CUSTOMER FOR DAMAGES.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">9.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">CONFIDENTIALITY<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">9.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Definition</span></b><span style="mso-bidi-font-family:
Arial">. “<b style="mso-bidi-font-weight:normal">Confidential Information</b>” includes
any information, technology, document, material, idea or data that is disclosed
by one party to the other, whether orally, electronically or in writing, that
is designated as confidential or that reasonably should be understood to be
confidential given the nature of the information, technology, document,
material, idea or data and the circumstances of the disclosure, including
information regarding a party’s products and services, business information,
customer lists, prices, financial information, intellectual property and other
trade secrets or confidential information, and anything tangible from which
such information may be obtained. <span style="mso-spacerun:yes">&nbsp;</span>Confidential
Information includes the terms and conditions of the Agreement and any Personal
Information disclosed or made available by one party to another. <span style="mso-spacerun:yes">&nbsp;</span>Wombat’s Confidential Information is expressly
understood to include the Services, related software code and other
intellectual property. <span style="mso-spacerun:yes">&nbsp;</span>Confidential Information,
however, shall not include any document, material, idea, data or other
information which: (a) is known to the receiving party under no obligation of
confidence, at the time of disclosure by the other party; (b) is lawfully
obtained by the receiving party from a third party who, in making such
disclosure, breaches no obligation of confidence to the other party; (c) is or
becomes publicly known through no wrongful act of the receiving party; or (d)
is independently developed by the receiving party without the use of the
disclosing party’s Confidential Information. <span style="mso-spacerun:yes">&nbsp;</span>The onus of proving that any of the
above-mentioned exceptions applies is on the receiving party.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">9.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Protection</span></b><span style="mso-bidi-font-family:
Arial">. Each party agrees to take all necessary precautions to maintain the
confidentiality of the other party’s Confidential Information disclosed in
connection with the Agreement by using at least the same degree of care as that
party uses with respect to its own Confidential Information of a like kind or
nature, but no less than a reasonable degree of care. <span style="mso-spacerun:yes">&nbsp;</span>Each party agrees not to use the other party’s
Confidential Information for any purpose not expressly permitted by the
Agreement and shall limit the disclosure of the other party’s Confidential
Information to the receiving party’s employees and contractors having a need to
know in connection with the provision by Wombat of the Services or Customer’s
use of the Services and any resulting Deliverables.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">9.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Compelled Disclosure</span></b><span style="mso-bidi-font-family:Arial">. The receiving party may disclose
Confidential Information of the disclosing party if it is compelled by law to
do so, provided the receiving party gives the disclosing party prior notice of
such compelled disclosure (to the extent legally permitted) and reasonable
assistance, at the disclosing party’s cost, if the disclosing party wishes to
object to the disclosure. <span style="mso-spacerun:yes">&nbsp;</span>If the
receiving party is compelled by law to disclose the disclosing party’s
Confidential Information as part of a civil proceeding to which the disclosing
party is a party, and the disclosing party is not objecting to the disclosure,
the disclosing party will reimburse the receiving party for its reasonable cost
of compiling and providing secure access to the Confidential Information.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">10.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">INFRINGEMENT<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">10.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Infringement Indemnity</span></b><span style="mso-bidi-font-family:Arial">. Subject to Sections 8.1 and 10.2, Wombat
will defend, indemnify and hold harmless Customer from and against any claims,
liability, and related costs, damages and other losses, resulting from a claim
that Customer’s use of the Services in accordance with the Agreement infringes
the Canadian IP Rights of any third party (an “<b style="mso-bidi-font-weight:
normal">Infringement Claim</b>”); provided that: (a) Customer promptly notifies
Wombat in writing within a reasonable period of time after learning of the
Infringement Claim; (b) Customer agrees to allow Wombat to control any
litigation and settlement related to the Infringement Claim; and (c) Customer
provides such reasonable assistance and cooperation as Wombat may require in
connection with the defense or settlement of the Infringement Claim. <span style="mso-spacerun:yes">&nbsp;</span>Wombat may not settle or compromise any
Infringement Claim without the prior written consent of Customer, if and to the
extent such settlement or compromise would result in any admission of liability
or payment by Customer for which Customer will not be indemnified by Wombat
pursuant to this Section 10.1.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">10.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Exclusions</span></b><span style="mso-bidi-font-family:
Arial">. Wombat shall have no liability with respect to any Infringement Claim
to the extent that such Infringement Claim: (a) would have been avoided but for
the combination, operation or use of the Services with any product, service,
equipment or software not provided or approved by Wombat, and which is not
required for the normal use of the Services; (b) is based on the operation or
use of the Services in a manner not consistent with the Documentation or in violation
of these Terms of Service; (c) would have been avoided but for the combination
or incorporation of any Customer Data together with the Services; (d) is based
on, or would have been avoided but for, any modifications to the Services or
Documentation made without Wombat’s express prior written approval; or (e) is
based on, or would have been avoided but for Wombat’s adherence to, Customer’s
or a reseller’s requirements or instructions.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">10.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Remedy</span></b><span style="mso-bidi-font-family:
Arial">. Should the Services become, or if Wombat reasonably believes that the Services
may likely become, subject to an Infringement Claim, then Wombat may, at its
sole option and expense: (a) procure the right for Customer to continue using
the affected Services; (b) replace the same with other software, services or
other material having equivalent functionality that is not subject to an
Infringement Claim; (c) modify the affected Services so that it is no longer
infringing; or (d) if Wombat determines that option (a), (b) or (c) cannot be
achieved on a commercially reasonable basis, terminate the Agreement and refund
to Customer the unused portion of any fees paid in advance by Customer for the
use of the affected Services, based on the number of full months, if any,
remaining in the Term.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">11.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">CUSTOMER
INDEMNITY<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:none"><span style="mso-bidi-font-family:Arial">Customer shall indemnify, defend (at Wombat’s+
election) and hold Wombat harmless from and against any third party claims,
losses, damages and expenses, including reasonable legal fees, arising from or
relating to: (a) Customer Data (other than Wombat’s or its personnel’s
unauthorized use or disclosure thereof); (b) any infringement, misappropriation
or violation of any IP Rights or privacy rights by Customer or any Authorized
User; (c) any violation of applicable law by Customer or an Authorized User; or
(d) Customer’s or an Authorized User’s use of the Services in violation of the
Agreement; provided that Wombat: (<span class="SpellE">i</span>) provides
Customer with written notice of such claim within a reasonable period of time
after learning of the claim; and (ii) provides reasonable cooperation in
response to Customer’s request for assistance. <span style="mso-spacerun:yes">&nbsp;</span>Customer may not settle or compromise any
indemnified claim without the prior written consent of Wombat, if and to the
extent such settlement or compromise would result in any admission of liability,
<span class="GramE">obligation</span> or payment by Wombat of any amounts for
which it will not be indemnified by Customer pursuant to this Section 11.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">TERM / TERMination
<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Term</span></b><span style="mso-bidi-font-family:
Arial">. The Initial Term is set out in the Accepted Order.<span style="mso-spacerun:yes">&nbsp; </span>Unless earlier terminated in accordance with
this Section 12, upon the expiry of the Initial Term, the Agreement will
automatically renew for successive <span class="GramE">one year</span> terms
unless either party provides the other with written notice of its intention not
to renew, not less than sixty (60) days prior to the expiry of the then-current
term.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Termination for Breach</span></b><span style="mso-bidi-font-family:Arial">. <span class="GramE">In the event that</span>
either party believes that the other has materially breached any obligations
under the Agreement, such party shall notify the breaching party in writing. <span style="mso-spacerun:yes">&nbsp;</span>The breaching party shall have thirty (30) days
from the receipt of notice to cure the alleged breach and to notify the
non-breaching party in writing that the breach has been cured. <span style="mso-spacerun:yes">&nbsp;</span>Except as provided in Section 6.3, if the
breach is not cured by the breaching party or the notice of breach is not
withdrawn by the non-breaching party within thirty (30) days, the non-breaching
party shall have the right to terminate the Agreement without further notice and
without the refund of any fees paid to Wombat; provided, however, that if Wombat
is the breaching party, Wombat will refund any unrealized amounts prepaid for
the Services on a prorated basis up to a maximum of six (6) months’ worth of
subscription fees.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Immediate Termination</span></b><span style="mso-bidi-font-family:Arial">. Either party (“<b style="mso-bidi-font-weight:
normal">Terminating Party</b>”) may terminate the Agreement effective
immediately (without providing a right to cure or refund of any portion of
fees) if the other party institutes a proceeding, a proceeding is commenced
against or affecting the other party, or the Terminating Party reasonably
believes that a proceeding will be instituted: (a) seeking to adjudicate it as
bankrupt or insolvent; (b) seeking liquidation, dissolution, winding up,
arrangement, protection, relief or composition of it or any of its property,
assets or debt; (c) making a proposal with respect to it under any law relating
to bankruptcy, insolvency, reorganization or compromise of debts or other
similar laws; or (d) seeking to appoint a receiver, trustee, agent, custodian
or other similar official for it or for all or part of its assets or property.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.4<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Suspension or Termination of Accounts</span></b><span style="mso-bidi-font-family:Arial">. Customer acknowledges and agrees that Wombat
reserves the right to suspend or terminate, upon not less than ten days’ prior
notice to Customer, the Account of any Authorized User that is, or who Wombat reasonably
believes is, in material violation of these Terms of Service, if the violation
is not rectified during the <span class="GramE">ten day</span> notice
period.<span style="mso-spacerun:yes">&nbsp; </span>Notwithstanding the foregoing,
Customer agrees that Wombat shall not be required to provide prior notice of
any suspension in cases where Wombat reasonably believes that such suspension
is required to prevent, or to prevent the continuation of, imminent harm to the
Services, the System, other Wombat assets or any party’s use of any of the
foregoing.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.5<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Changes or Discontinuance of a Service</span></b><span style="mso-bidi-font-family:Arial">. Wombat may change or terminate features or
functionality of a Service in its sole discretion at any time upon not less
than sixty (60) days’ prior written notice to Customer.<b style="mso-bidi-font-weight:
normal"><o:p></o:p></b></span></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:none"><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></b></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">12.6<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Effect of Termination</span></b><span style="mso-bidi-font-family:Arial">. Upon the termination, expiration or
non-renewal of the Agreement: (a) Customer shall pay all undisputed fees and
other amounts owing to Wombat for the Services up to and including the
effective date of termination or expiry; (b) Customer’s rights to use the
Services, including its right to use the Services, shall terminate and Customer
(including all Authorized Users) shall immediately cease use of the Services;
(c) each of the parties shall deliver or destroy all Confidential Information
of the other party which is in its possession or under its care or control; (d)
if requested by Customer, Wombat shall provide, at Customer’s expense, a
machine-readable copy of any Customer Data, and shall thereafter promptly
irretrievably delete all Customer Data from the Services and the System; and (e)
any terms and conditions of the Agreement, which by their nature extend beyond
the termination or expiry of the Agreement, shall survive the termination or
expiry of the Agreement, including Sections 3.2, 4.3, 5, 6.3, 7, 8, 9, 10, 11,
12.6, 13.1, 13.7, 13.8, 13.10 and 13.11 of these Terms of Service.<o:p></o:p></span></p>

<h1 style="margin-left:0cm;text-indent:0cm;mso-list:l25 level1 lfo28"><!--[if !supportLists]--><span style="mso-fareast-font-family:Arial;mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span style="mso-bidi-font-family:Arial">MISCELLANEOUS<o:p></o:p></span></h1>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Governing Law</span></b><span style="mso-bidi-font-family:Arial">.</span><a name="_Ref444688347"><span style="mso-fareast-font-family:&quot;Times New Roman&quot;;mso-bidi-font-family:Arial"> </span></a><span style="mso-bookmark:_Ref444688347"><span style="mso-bidi-font-family:Arial">The
Agreement shall be construed, <span class="GramE">interpreted</span> and enforced
in accordance with, and the respective rights and obligations of the parties
shall be exclusively governed by, the laws of the Province of British Columbia
and the federal laws of Canada applicable in that province, as if it had been
wholly performed within British Columbia, but excluding conflicts of laws
provisions.<span style="mso-spacerun:yes">&nbsp; </span>The parties expressly
exclude the application of the United Nations Convention on Contracts for the
International Sale of Goods (also referred to as the "Vienna Convention").</span></span><span style="mso-bidi-font-family:Arial"><o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Severability</span></b><span style="mso-bidi-font-family:Arial">. <span class="GramE">In the event that</span>
any provision of the Agreement is determined to be unenforceable or invalid
under any applicable law or by applicable court decision, such unenforceability
or invalidity shall not render the Agreement unenforceable or invalid as a
whole and, in such event, such provision shall be changed and interpreted so as
to best accomplish the objectives of such provision within the limits of
applicable law or applicable court decisions.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Waiver</span></b><span style="mso-bidi-font-family:
Arial">. The waiver by either party of a breach of any provisions of the
Agreement shall be in writing and shall in no way be construed as a waiver of
any other breach or of any succeeding breach of the provision or the waiver of
the provision itself.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.4<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Assignment</span></b><span style="mso-bidi-font-family:
Arial">. The Agreement, or any part thereof, may not be assigned by Customer
without the prior written approval of Wombat, which will not be unreasonably
withheld or delayed. <span style="mso-spacerun:yes">&nbsp;</span>The Agreement will
be binding on and <span class="SpellE">enure</span> to the benefit of the parties
and their respective successors and permitted assigns.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.5<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Independent Contractors</span></b><span style="mso-bidi-font-family:Arial">. Wombat and Customer are independent
contractors and neither the Agreement, nor any party thereof, will be deemed to
establish any partnership, joint venture, employment, <span class="GramE">franchise</span>
or agency between them. <span style="mso-spacerun:yes">&nbsp;</span>Neither party
will have the power to bind the other or incur obligations on the other’s
behalf without the other’s prior written consent.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.6<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Force Majeure</span></b><span style="mso-bidi-font-family:Arial">. Except with respect to payment obligations,
the time for performance of a party’s obligations shall automatically be
extended by the period during which one party is prevented from performing its
obligations due to any cause beyond its reasonable control (a “<b style="mso-bidi-font-weight:normal">Force Majeure Event</b>”). <span style="mso-spacerun:yes">&nbsp;</span>The party that is unable to perform <span class="GramE">as a result of</span> the Force Majeure Event will: (a) notify the
other party immediately; (b) use commercially reasonable efforts to mitigate
the effects of the Force Majeure Event; and (c) effect performance of its
affected obligations under the Agreement as soon as reasonably possible,
including through the use of alternate resources or workarounds.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.7<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Disputes</span></b><span style="mso-bidi-font-family:
Arial">. In the event of any dispute arising out of the Agreement, the parties
shall use <span class="GramE">reasonable</span> good faith efforts to negotiate a
mutually satisfactory resolution.<span style="mso-spacerun:yes">&nbsp; </span>If
they are unable to do so within a period of sixty (60) days (or such other
longer period as the parties may agree), then either party may, on written
notice to the other party, refer the dispute for final resolution by arbitration
before a single arbitrator administered by the Vancouver International Arbitration
Centre pursuant to its applicable Rules.<span style="mso-spacerun:yes">&nbsp;
</span>The parties shall select the arbitrator promptly and use commercially
reasonable efforts to conduct the arbitration hearing no later than three (3)
months after the arbitrator is selected.<span style="mso-spacerun:yes">&nbsp;
</span>The arbitrator may not award punitive or exemplary damages against
either party or any other relief <span class="GramE">in excess of</span> the
limitations set forth in the Agreement.<span style="mso-spacerun:yes">&nbsp;
</span>The judgment and award of the arbitrator will be final and binding on
each party.<span style="mso-spacerun:yes">&nbsp; </span>Judgment upon the award may
be entered in any court having jurisdiction, or application may be made to such
court for judicial acceptance of the award and/or an order of <span class="GramE">enforcement as the case may be</span>.<span style="mso-spacerun:yes">&nbsp; </span>No action, regardless of form, arising out of
or in connection with the Agreement may be brought by Customer more than twelve
(12) months after the occurrence of the event giving rise to the cause of
action, regardless of whether the Customer was aware or ought reasonably to
have been aware of the event.<b style="mso-bidi-font-weight:normal"><o:p></o:p></b></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.8<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Injunctive Relief</span></b><span style="mso-bidi-font-family:Arial">. Notwithstanding Section 13.7, nothing in
the Agreement shall prevent either party from applying to a court of competent
jurisdiction for injunctive or other equitable relief in the case of an
infringement of IP Rights (including any breach of use or license rights), data
breach, breach of obligations with respect to Personal Information, or the
breach of an obligation of confidentiality, to preserve or protect real or
tangible property from continuing damage or risk of same or to preserve a legal
right for which the applicable limitation period is about to expire.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.9<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Authority</span></b><span style="mso-bidi-font-family:
Arial">. Each party represents and warrants that: (a) it is a corporation
validly subsisting under the laws of the jurisdiction in which it is
incorporated; (b) it possesses full power and authority to enter into the
Agreement and to perform its obligations hereunder; (c) its performance of the
terms of the Agreement will not breach any separate agreement by which such
party is bound; and (d) it shall at all times comply with applicable laws.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.10<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Legal
Compliance</span></b><span style="mso-bidi-font-family:Arial">. The Services,
Documentation and other Wombat Property and materials are subject to the export
control laws of various countries, including Canada. <span style="mso-spacerun:yes">&nbsp;</span>Customer agrees that it will not submit the Services,
<span class="GramE">Documentation</span> or other Wombat Property to any
government agency for licensing consideration or other regulatory approval
without the prior written consent of an authorized representative of Wombat,
and that it will not use or permit the use of the Services, Documentation or Wombat
Property in countries or by persons prohibited by such laws.<span style="mso-spacerun:yes">&nbsp; </span>Customer shall also be responsible for
complying with all applicable laws of any country where Customer carries on
business, with respect to the use of the Services, Documentation, or other Wombat
Property and materials.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.11<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">United
States Government End Use</span></b><span style="mso-bidi-font-family:Arial">. If
the Customer or any Authorized User is the Government of the United States, Wombat
provides the Services, including related software and technology, for ultimate
federal government end use solely in accordance with the following: Government
technical data and software rights related to the Services include only those
rights customarily provided to the public as defined in these Terms of Service.
<span style="mso-spacerun:yes">&nbsp;</span>This customary commercial license is
provided in accordance with FAR 12.211 (Technical Data) and FAR 12.212
(Software) and, for Department of Defense transactions, DFAR 252.227-7015
(Technical Data – Commercial Items) and DFAR 227.7202-3 (Rights in Commercial
Computer Software or Computer Software Documentation). <span style="mso-spacerun:yes">&nbsp;</span>If a government agency has a need for rights
not conveyed under these Terms of Service, it must negotiate with Wombat to
determine if there are acceptable terms for transferring such rights, and a
mutually acceptable written addendum specifically conveying such rights must be
included in any applicable contract or agreement.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.12<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Publicity</span></b><span style="mso-bidi-font-family:Arial">. Unless expressly prohibited in writing by
Customer, during the Term, Wombat may publicly refer to Customer and identify
Customer as a Wombat customer, whether orally or in writing, including in a
customer directory list on Wombat’s public website or other Wombat marketing
collateral.<span style="mso-spacerun:yes">&nbsp; </span>Wombat may use Customer’s
name in a press release disclosing the parties and the nature of their relationship
and in Wombat advertising, in each case with the prior written approval of
Customer, such approval not to be unreasonably withheld. <span style="mso-spacerun:yes">&nbsp;</span>Any other public or marketing reference to
Customer by Wombat shall require the written consent of Customer, such consent
not to be unreasonably withheld.<o:p></o:p></span></p>

<p class="MsoBodyText" style="margin-top:0cm"><o:p>&nbsp;</o:p></p>

<p style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;mso-list:l25 level2 lfo28"><!--[if !supportLists]--><b style="mso-bidi-font-weight:normal"><span style="mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial"><span style="mso-list:Ignore">13.13<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-bidi-font-family:Arial">Notices</span></b><span style="mso-bidi-font-family:Arial">. Any notice or other significant
communication given to either party pursuant to the Agreement shall be in
writing sent by email to Wombat at <span class="SpellE">support@wombat.software</span>
and to Customer at the address specified in the Accepted Order.<o:p></o:p></span></p>

<div>`,
  createdAt: "",
};
