// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function traverseObjectPath<Value>(path: string, srcObject: any) {
  const stack = path.split(".");
  let prop = stack.shift();
  let targetObj = srcObject;
  while (prop && targetObj) {
    targetObj = targetObj[prop];
    prop = stack.shift();
  }

  if (!targetObj) {
    return targetObj;
  }

  if (Array.isArray(targetObj)) {
    return [...targetObj];
  } else if (targetObj !== null && typeof targetObj === "object") {
    // THINK: dont spread here, you lose the instantiated type? (ie, Timestamp)
    // return targetObj;
    return { ...targetObj };
  } else {
    return targetObj as Value;
  }
}

/**
 * Performs equality by checking Object.is then JSON.stringify compare
 */
export function deepEqual(objA: unknown, objB: unknown): boolean {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  return JSON.stringify(objA) === JSON.stringify(objB);
}
