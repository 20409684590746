//  libraries
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const CONTACT_INFORMATION_FORM_GROUP: IFieldGroupBuilt = {
  name: "userContactInformation",
  label: "Contact Information",
  fields: [
    // {
    //   name: "email",
    //   span: 4,
    //   label: "Email Address",
    //   fieldType: IFieldTypes.emailField,
    //   conditions: [
    //     {
    //       parameter: "[[linkedAuthentication]]",
    //       operation: "==",
    //       value: true,
    //       action: "readonly",
    //     },
    //   ],
    //   description:
    //     `The email address used to contact this individual, including system notifications.
    //      This may not be editable if the users account is managed by the organization.`,
    // },
    {
      name: "officePhone",
      span: 1,
      label: "Office Phone",
      fieldType: IFieldTypes.phoneField,
    },
    {
      name: "mobilePhone",
      span: 1,
      label: "Mobile Phone",
      fieldType: IFieldTypes.phoneField,
    },
    {
      name: "homePhone",
      span: 1,
      label: "Home Phone",
      fieldType: IFieldTypes.phoneField,
    },
  ],
};
