// Libraries
import { useCallback, useState } from "react";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// services
import requestService from "../services/request.service";

// hooks
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";
import { useToastContext } from "../contexts/toast.context";

// utilities
import { isObject } from "wombat-global/src/utilities/object";

// constants
import { TERMS_CONDITIONS } from "wombat-global/src/constants/terms-conditions/v1";

export const TermsConditionsModalModal: React.FC = () => {
  const { reload, logout, acceptedTC, isAuthenticated } =
    useAuthenticationContext();
  const { addToast } = useToastContext();
  const [disabled, setDisabled] = useState(false);

  const open = Boolean(
    isAuthenticated && acceptedTC !== TERMS_CONDITIONS.version,
  );

  const handleErr = useCallback(
    (err: unknown) => {
      if (typeof err === "string") {
        addToast(`Err: ${err}`);
      } else if (isObject(err) && err.message) {
        addToast(`Err: ${err.message}`);
      } else {
        addToast(`Err: ${(err as string).toString()}`);
      }
    },
    [addToast],
  );

  const handleDecline = useCallback(async () => {
    setDisabled(true);
    try {
      addToast("Err: Terms and Conditions not accepted");
      await logout();
    } catch (err) {
      if (open) {
        handleErr(err);
      }
    } finally {
      setDisabled(false);
    }
  }, [addToast, handleErr, logout, open]);

  const handleAcceptance = useCallback(async () => {
    setDisabled(true);
    try {
      await requestService.request("/api/terms-conditions", {
        method: "post",
        body: JSON.stringify({
          acceptedVersion: TERMS_CONDITIONS.version,
        }),
      });
      await reload();
    } catch (err) {
      if (open) {
        handleErr(err);
      }
    } finally {
      setDisabled(false);
    }
  }, [handleErr, open, reload]);

  return (
    <Dialog
      open={open}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      sx={{ zIndex: 1303 }}
      PaperProps={{
        sx: { p: 1, maxWidth: "1000px", width: "100%" },
      }}
    >
      <DialogTitle
        color="text.primary"
        id="confirmation-dialog-title"
        sx={{ textAlign: "center", lineHeight: "135%" }}
      >
        Wombat Software Inc. <br /> Software as a Service Subscription Agreement{" "}
        <br /> Terms of Service
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          <div
            dangerouslySetInnerHTML={{
              __html: TERMS_CONDITIONS.content,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={disabled}
          variant="outlined"
          onClick={handleDecline}
          color="error"
        >
          Decline
        </Button>
        <Button
          disabled={disabled}
          variant="outlined"
          onClick={handleAcceptance}
          color="success"
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};
