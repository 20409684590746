// libraries
import { useMemo } from "react";
import { Navigate, useMatch } from "@tanstack/react-location";

// hook
import { useIsAdmin } from "../hooks/useIsAdmin";
import { useFeatureFlags } from "../hooks/useFeatureFlags";
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";

import { featureIsEnabled } from "wombat-global/src/feature-flags.maps";

// constants
import { READ } from "wombat-global/src/permission.maps";

// types
import { PathConfig } from "./types";
import { LoadingProgress } from "../components/UI/LoadingProgress";
import { getTargetFromParams } from "./utilities";
import { IClientTarget, ISiteTarget } from "wombat-global/src/typings";

export function Auth({
  children,
  params,
  config,
}: {
  children: React.ReactNode;
  config?: PathConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
}): JSX.Element {
  const target = getTargetFromParams(params);
  const { isAuthenticated, initialized, hasPermission } =
    useAuthenticationContext();
  const [isAdmin, adminCheckLoading] = useIsAdmin(
    target as IClientTarget | ISiteTarget,
  );
  const redirectTo = useMatch();

  /**
   * feature flags rely on org and tenant data to be loaded
   */
  const [featureFlags, flagsLoading] = useFeatureFlags({ target });

  const featureEnabled = config?.feature
    ? featureIsEnabled(featureFlags, config?.feature)
    : true;

  const userHasPermission = useMemo(() => {
    /**
     * pages with a specific required permission bit
     */
    if (target && config?.permission) {
      return (
        (typeof config.permission === "number"
          ? [config.permission]
          : config.permission
        ).some((perm) => hasPermission(target, perm)) || isAdmin
      );
    } else if (target && config?.private) {
      /**
       * private pages within an org / tenant
       *
       * NOTE: this will return true in the case where your and admin (permission at the org)
       * and your accessing a tenant that DNE; therefore, we need to check that the tenant data is
       * fetchable (its exists)
       */
      return (
        (isAdmin || hasPermission(target, READ.bitFlag)) && isAuthenticated
      );
    } else if (config?.private) {
      /**
       * Private pages outside of an org / tenant
       */
      // if its private, make user user is logged in
      return isAuthenticated;
    }
    // else its public
    return true;
  }, [
    config?.permission,
    config?.private,
    hasPermission,
    isAdmin,
    isAuthenticated,
    target,
  ]);

  /**
   * renders
   */
  if (initialized && !adminCheckLoading && !isAuthenticated) {
    return <Navigate to={`/login?redirectTo=${redirectTo.pathname}`} />;
  }

  if ((config?.feature !== undefined && flagsLoading) || adminCheckLoading) {
    return <LoadingProgress />;
  }

  if (initialized && !adminCheckLoading && !userHasPermission) {
    return <Navigate to={"/404"} />;
  }

  if (config?.feature !== undefined && !featureEnabled && !adminCheckLoading) {
    return <Navigate to="/feature-unavailable" />;
  }

  return <>{children}</>;
}
