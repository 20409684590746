import { QueryClient } from "@tanstack/react-query";
import { Time } from "wombat-global/src/utilities/time";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * Time.mins,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      retryDelay: 1000,
      retry(failureCount, error) {
        if (failureCount > 2) {
          // eslint-disable-next-line no-console
          console.error(error);
          return false;
        }
        return true;
      },
      retryOnMount: true,
    },
  },
});

export const subscriptionQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 5 * Time.mins,
      staleTime: Infinity,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
    },
  },
});
