import { useCallback, useState } from "react";

export function useToggle(
  init: boolean,
): [boolean, (val?: boolean) => () => void] {
  const [state, setState] = useState(init);
  const toggle = useCallback(
    (val?: boolean) => () => {
      if (val === undefined) {
        setState((v) => !v);
      } else {
        setState(val);
      }
    },
    [setState],
  );
  return [state, toggle];
}
