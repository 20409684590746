import { ObjectEntryPermissionGuard } from "./permission-guard";
import { ICondition, TFieldValue } from "./utils";

import { isObject } from "../../utilities/object";

// eslint-disable-next-line no-shadow
export enum IFieldTypes {
  "matrixField" = "matrixField",
  "dateField" = "dateField",
  "timeField" = "timeField",
  "textArea" = "textArea",
  "textField" = "textField",
  "phoneField" = "phoneField",
  "emailField" = "emailField",
  "signatureField" = "signatureField",
  "numberField" = "numberField",
  "toggle" = "toggle",
  "checkbox" = "checkbox",
  "password" = "password",
  "timezone" = "timezone",
  "autocomplete" = "autocomplete",
  "asyncAutocompleteField" = "asyncAutocompleteField",
  "select" = "select",
  "radio" = "radio",
  "videoPlayer" = "videoPlayer",
  "colorField" = "colorField",
  "userSelectField" = "userSelectField",
  "documentSelectField" = "documentSelectField",
  "fileSelect" = "fileSelect",
  "imageSelect" = "imageSelect",
  "videoSelect" = "videoSelect",
  "inspectionQuestion" = "inspectionQuestion",
  "subForm" = "subForm",
  "bigToggleField" = "bigToggleField",
}

// eslint-disable-next-line no-shadow
export enum IElementTypes {
  "divider" = "divider",
  "header" = "header",
  "text" = "text",
  "textEditor" = "textEditor",
}

/**
 * Type expected for Lookup (Select) filed options
 */
export interface IFieldOptions<T = Record<string, unknown>> {
  key?: string;
  label: string;
  value: string | number | boolean | null;
  meta?: T;
}

/**
 * Reference to a field definition.
 * Within the field/group configuration you can reuse field configs and give them new names/labels
 */

export interface IFieldReference {
  name: string;
  label: string;
  fieldId: string;

  /**
   * Add permission level check to the fieldGroup
   * - This will only be useful when used within a form, object, or training
   */
  permissionGuard?: ObjectEntryPermissionGuard[];

  // conditions that must be met in order to render this group
  conditions?: ICondition[];
}

export function isFieldReference(field?: unknown): field is IFieldReference {
  return (
    isObject(field) &&
    Object.getOwnPropertyNames(field).includes("name") &&
    Object.getOwnPropertyNames(field).includes("label") &&
    Object.getOwnPropertyNames(field).includes("fieldId")
  );
}

export const isField = (obj: unknown): obj is IField => {
  return Boolean(
    isObject(obj) &&
      obj.fieldType &&
      Object.values(IFieldTypes as Record<string, unknown>).includes(
        obj.fieldType,
      ),
  );
};

export const isElement = (obj: unknown): obj is IElement => {
  return Boolean(
    isObject(obj) &&
      obj.fieldType &&
      Object.values(IElementTypes as Record<string, unknown>).includes(
        obj.fieldType,
      ),
  );
};

/**
 * Form Field configuration. used to dynamically render a field within a form within the react app.
 * This structure gets saved in the database within a FieldSchema or a FieldGroupSchema
 */
export interface IFormElementBase {
  name: string;
  conditions?: ICondition[];
  permissionGuard?: ObjectEntryPermissionGuard[];
  hidden?: boolean;
  span?: 1 | 2 | 3 | 4;
  disabled?: boolean;
  readonly?: boolean;
}

export interface IFieldBase extends IFormElementBase {
  label: string;
  labelPlacement?: "inline" | "above";
  defaultValue?: TFieldValue | string[];
  hidden?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  // not strictly required for select
  required?: boolean;
  description?: string;

  autoFocus?: boolean;
  // max length or number of selected options
  min?: number;
  // min length or number of selected options
  max?: number;
}

export type IFieldAutocomplete = IFieldBase & {
  fieldType: IFieldTypes.autocomplete;
  options: IFieldOptions[];
  freeSolo?: boolean;
  multiple?: boolean;
};

export type IFieldAsyncAutocomplete = IFieldBase & {
  fieldType: IFieldTypes.asyncAutocompleteField;
  queryFn?: (input?: string) => Promise<IFieldOptions[]>;
  queryOptions?: () => Promise<IFieldOptions[]>;
};

export type IElementText = IFormElementBase & {
  fieldType: IElementTypes.text;
  text?: string;
  disabled?: boolean;
  readonly?: boolean;
};
export type IElementWysiwygEditor = IFormElementBase & {
  fieldType: IElementTypes.textEditor;
  documentObject: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (documentObject: object) => void;
};

// eslint-disable-next-line no-shadow
export enum HeaderOptions {
  "h1" = "h1",
  "h2" = "h2",
  "h3" = "h3",
}

export type IElementHeader = IFormElementBase & {
  fieldType: IElementTypes.header;
  text?: string;
  meta?: { headerType: HeaderOptions; [s: string]: unknown };
  disabled?: boolean;
  readonly?: boolean;
};

export type IFieldUserSelect = Omit<
  IFieldAutocomplete,
  "fieldType" | "options"
> & {
  fieldType: IFieldTypes.userSelectField;
};
export interface IDocumentFieldOptions
  extends IFieldOptions<Record<string, unknown>> {
  isFolder?: boolean | null;
  level?: number;
}

export type IFieldDocumentSelect = Omit<
  IFieldAutocomplete,
  "fieldType" | "options"
> & {
  fieldType: IFieldTypes.documentSelectField;
  options: IDocumentFieldOptions[];
};

export type IFieldSelect =
  | (IFieldBase & {
      fieldType: IFieldTypes.select;
      options: IFieldOptions[];
      multiple?: boolean;
      defaultValue?: TFieldValue | TFieldValue[];
    })
  | (IFieldBase & {
      fieldType: IFieldTypes.select;
      options: IFieldOptions[];
      multiple?: true;
      defaultValue?: TFieldValue[];
    });

export type IFieldBigToggle = IFieldBase & {
  fieldType: IFieldTypes.bigToggleField;
  options: IFieldOptions[];
};

export type IFieldRadio = IFieldBase & {
  fieldType: IFieldTypes.radio;
  defaultValue?: TFieldValue;
  options: IFieldOptions[];
};

export type IFieldDate = IFieldBase & {
  fieldType: IFieldTypes.dateField;
  outputNumber?: boolean;
  outputFormat?: string;
};

export type IFieldTime = IFieldBase & {
  fieldType: IFieldTypes.timeField;
  outputNumber?: boolean;
};

export type IFieldFileSelect = IFieldBase & {
  fieldType: IFieldTypes.fileSelect;
  accept?: string;
  multiple?: boolean;
};

export type IFieldVideoSelect = IFieldBase & {
  fieldType: IFieldTypes.videoSelect;
};

export type IFieldColorSelect = IFieldBase & {
  fieldType: IFieldTypes.colorField;
  inline?: boolean;
};

export type IFieldImageSelect = IFieldBase & {
  fieldType: IFieldTypes.imageSelect;
  multiple?: boolean;
};

export type InspectionQuestionField = IFieldBase & {
  fieldType: IFieldTypes.inspectionQuestion;
  options: IFieldOptions[];
  allowActionCreation?: boolean;
  allowedCorrectiveActions?: string[];
  allowComment?: boolean;
  allowFile?: boolean;
};

export type IVideoField = IFieldBase & {
  fieldType: IFieldTypes.videoPlayer;
  metadata?: {
    videoManager?: string;
  };
  videoItemId?: string;
  videoURL?: string;
  posterURL?: string;
  playerConfig: {
    allowPause?: boolean;
    allowRewind?: boolean;
    allowFastForward?: boolean;
    allowPlayBackSpeed?: boolean;
    allowCloseCaption?: boolean;
    allowChangeQuality?: boolean;
    allowOfflinePlay?: boolean;
  };
};

export type MatrixQuestionField = IFieldBase & {
  fieldType: IFieldTypes.matrixField;
  matrixMap: Record<string, IFieldOptions>;
  xLabel: string;
  yLabel: string;
  xAxisKeys: { key: string; label: string; description: string }[];
  yAxisKeys: { key: string; label: string; description: string }[];
};

export type ISubForm = IFieldBase & {
  fieldType: IFieldTypes.subForm;

  // Ids refer to build schemas that are selectable.
  // - If ids is unset then all are selectable
  form: {
    type: "form" | "corrective-action";
    ids?: string[];
    ref?: string;
  };
};

export type IField =
  | (IFieldBase & {
      fieldType:
        | IFieldTypes.textArea
        | IFieldTypes.textField
        | IFieldTypes.numberField
        | IFieldTypes.signatureField
        | IFieldTypes.phoneField
        | IFieldTypes.emailField
        | IFieldTypes.toggle
        | IFieldTypes.checkbox
        | IFieldTypes.password
        | IFieldTypes.timezone;
    })
  | IFieldColorSelect
  | IFieldAutocomplete
  | IFieldFileSelect
  | IFieldImageSelect
  | IFieldVideoSelect
  | IFieldDate
  | IFieldTime
  | IFieldSelect
  | IFieldRadio
  | IFieldDocumentSelect
  | IFieldUserSelect
  | IFieldBigToggle
  | IFieldAsyncAutocomplete
  | InspectionQuestionField
  | MatrixQuestionField
  | IVideoField
  | ISubForm;

export type IElement =
  | (IFormElementBase & {
      fieldType: IElementTypes.divider;
    })
  | IElementText
  | IElementWysiwygEditor
  | IElementHeader;

/**
 * Field Configurations override the default behavior of a field.
 * It keys of of the fieldId
 */
export interface IFieldConfiguration {
  fieldId: string;
  conditions?: ICondition[];
  options?: IFieldOptions[];
  min?: number;
  max?: number;
  defaultValue?: TFieldValue;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}
