// libraries
import { createContext } from "react";

// types
import { SubscriptionContextType } from "./subscription.types";

export const SubscriptionContext = createContext<SubscriptionContextType>({
  state: {},
  removeFirestoreSub: () => null,
  addFirestoreSnapshot: () => null,
  addFirestoreCollectionSnapshot: () => null,
  cleanSubscriptions: () => null,
  encodeKey: () => "",
});
