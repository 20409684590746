// libraries
import { v4 } from "uuid";
import { forEachLimit } from "async";
import { getDocFromServer } from "firebase/firestore";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// hooks
import { useToastContext } from "../toast.context";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useConnectivityStatus } from "../connectivity.context";
import { useDocSnap } from "../../hooks/useSubscription.v2";
import { useAuthenticationContext } from "../authentication/authentication.context";

// utilities
import { swApi } from "../../service-workers/firebase-pre-cache/firebase-pre-cache.api";
import { getRef } from "../../services/firebase";
import requestService from "../../services/request.service";
import { resolveQueueItem } from "../../service-workers/firebase-pre-cache/firebase-pre-cache.utilities";
import { precacheUsersOfflineDocuments } from "./precacheUsersOfflineDocuments";

// constants
import {
  ALL_OBJECTS,
  CURRENT_METADATA_DOCUMENT,
  DOCUMENTS_META_COLLECTION,
  GLOBAL_USERS_COLLECTION,
  INDEXED_COLLECTION,
  getDocumentPath,
  getSchemaPath,
  targetPath,
} from "wombat-global/src/constants";
import { Time } from "wombat-global/src/utilities/time";

// types
import {
  IGlobalUserEntity,
  MetaDocumentsDocument,
  OfflineStorageConsent,
  AllObjectsReference,
  IClientTarget,
  ISiteTarget,
} from "wombat-global/src/typings";

export type OfflineRequestEvent = {
  key: string;
  type: string;
  eventName: "Save" | "Submit" | "Update";
  state: "pending" | "uploading" | "completed" | "errored";
  data: OfflineEvent;
  createdAt: number;
  updatedAt?: number;
};

type OfflineSupportContextApi = {
  offline: boolean;
  offlineEvent: Record<string, OfflineRequestEvent>;
  syncWithCloud: (keys?: string[]) => void;
  pushOfflineDataRequest: (
    key: string,
    requests: OfflineEvent["requests"],
  ) => void;
  upsertOfflineData: (
    key: string | undefined,
    eventName: OfflineRequestEvent["eventName"],
    type: OfflineRequestEvent["type"],
    data: OfflineEvent,
  ) => void;
};

const OfflineSupportContext = React.createContext<OfflineSupportContextApi>({
  offline: true,
  offlineEvent: {},
  upsertOfflineData: () => null,
  pushOfflineDataRequest: () => null,
  syncWithCloud: () => null,
});

function metaDocRef(target: IClientTarget | ISiteTarget) {
  return getRef(
    `${targetPath(
      target,
    )}/${DOCUMENTS_META_COLLECTION}/${CURRENT_METADATA_DOCUMENT}`,
  );
}

async function getMetaDocumentDocument(
  target: IClientTarget | ISiteTarget,
): Promise<MetaDocumentsDocument | undefined> {
  return await getDocFromServer(metaDocRef(target)).then((d) => {
    // eslint-disable-next-line no-console
    console.log("get meta document document", target, d.data(), d);
    return d.data() as MetaDocumentsDocument | undefined;
  });
}

async function getAllForms(
  target: IClientTarget | ISiteTarget,
): Promise<Record<string, AllObjectsReference> | undefined> {
  return await getDocFromServer(
    getRef(`${targetPath(target)}/${INDEXED_COLLECTION}/${ALL_OBJECTS}`),
  ).then((d) => {
    // eslint-disable-next-line no-console
    console.log("get all forms", target, d.data(), d);
    return d.data() as Record<string, AllObjectsReference> | undefined;
  });
}

type OfflineEvent = {
  state: Record<string, unknown>;
  requests: { endpoint: string; method: string; body: string }[];
};

async function syncOffline({
  key,
  type,
  uid,
  consent,
  target,
}: {
  key: string;
  type: "forms" | "documents";
  uid: string;
  consent: OfflineStorageConsent;
  target: IClientTarget | ISiteTarget;
}) {
  const syncedOn = localStorage.getItem(`firebase-precache.${uid}.${key}`);
  const expiredSync =
    !syncedOn ||
    new Date().getTime() - new Date(syncedOn).getTime() > Time.days;

  /**
   * if user has consented to store org/tenant forms or documents continue
   */
  if (!consent || !consent.store || !expiredSync) {
    return;
  }

  /**
   * sync published documents
   */
  if (type === "documents") {
    const documentMeta = await getMetaDocumentDocument(target);

    const items = Object.entries(documentMeta?.data || {})
      /* Precache only if the item has been published */
      .filter(([, _doc]) => _doc.publishedAt)
      .map(([documentId, _doc]) => ({
        batch: key,
        path: getDocumentPath({ target, documentId }),
        meta: {
          publishedAt: _doc.publishedAt,
        },
      }));

    /**
     * Push precache items into service worker queue
     */
    // swApi.push(items, key);
    await Promise.all(items.map((item) => resolveQueueItem(item)));
  }

  /**
   * sync published forms
   */
  if (type === "forms") {
    const allForms = await getAllForms(target);
    const items = Object.entries(allForms || {})
      /* Precache only if the item has been published */
      .filter(([, schema]) => schema.builtAt)
      .map(([schemaId, schema]) => ({
        batch: key,
        path: getSchemaPath({ target, schemaId }),
        meta: {
          builtAt: schema.builtAt,
        },
      }));

    /**
     * Push precache items into service worker queue
     */
    // swApi.push(items, key);
    await Promise.all(items.map((item) => resolveQueueItem(item)));
  }
}

export const OfflineSupportProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { user, uid } = useAuthenticationContext();
  const online = useConnectivityStatus();
  const [onlineShadow, setOnlineShadow] = useState<boolean>(false);
  const { addToast } = useToastContext();
  const [offlineData, setOffline] = useLocalStorage<
    OfflineSupportContextApi["offlineEvent"]
  >("offline-form-storage", {});

  const [globalUser] = useDocSnap<IGlobalUserEntity>(
    `${GLOBAL_USERS_COLLECTION}/${user?.uid}`,
    {
      enabled: Boolean(user?.uid),
    },
  );

  const upsertOfflineData = useCallback(
    (
      key: string | undefined,
      eventName: OfflineRequestEvent["eventName"],
      type: OfflineRequestEvent["type"],
      data: OfflineEvent,
    ) => {
      const _key = key || v4();
      setOffline((_d) => ({
        ..._d,
        [_key]: {
          ...(_d[_key] || {}),
          key: _key,
          type,
          data,
          eventName,
          updatedAt: Date.now(),
          ...(_d[_key] ? {} : { state: "pending", createdAt: Date.now() }),
        },
      }));
    },
    [setOffline],
  );

  const pushOfflineDataRequest = useCallback(
    (key: string, requests: OfflineEvent["requests"]) => {
      const _key = key || v4();
      setOffline((_d) => {
        if (!_d[key]) {
          // eslint-disable-next-line
          console.warn("Offline data key DNE, Cannot add request");
          return _d;
        }

        return {
          ..._d,
          [_key]: {
            ..._d[_key],
            data: {
              ..._d[_key].data,
              requests: [..._d[_key].data.requests, ...requests],
            },
          },
        };
      });
    },
    [setOffline],
  );

  /**
   * @private
   * delayed removal of queue item
   */
  const removeEvent = useCallback(
    (key: string) => {
      setTimeout(() => {
        setOffline(({ [key]: data, ...d }) => d);
      }, 5 * Time.seconds);
    },
    [setOffline],
  );

  /**
   * @private
   * internal queue item state setter
   */
  const setEventState = useCallback(
    (key: string, state: OfflineRequestEvent["state"]) => {
      setOffline((d) => ({
        ...d,
        [key]: {
          ...d[key],
          state: state,
        },
      }));
    },
    [setOffline],
  );

  const syncWithCloud = useCallback(
    (keys?: string[]) => {
      return forEachLimit(
        keys || Object.keys(offlineData),
        2,
        (key, callback) => {
          const req = offlineData[key];
          // this last loop must happen one request ofter the other (sequencing matters)
          forEachLimit(req.data.requests, 1, (_req, innerCallback) => {
            requestService
              .request(_req.endpoint, { body: _req.body, method: _req.method })
              .then((d) => {
                setEventState(key, "completed");
                removeEvent(key);
                return;
              })
              .catch((err) => {
                setEventState(key, "errored");
                throw err;
              })
              .then(() => innerCallback())
              .catch(innerCallback);
          })
            .then(() => callback())
            .catch(callback);
        },
      );
    },
    [offlineData, setEventState, removeEvent],
  );

  /**
   * When app comes online, push up pending / errored requests
   */
  useEffect(() => {
    if (online && !onlineShadow && Object.keys(offlineData).length > 0) {
      // eslint-disable-next-line
      console.log("Pushing saved request to cloud");
      syncWithCloud();
    }
    setOnlineShadow(online);
  }, [online, syncWithCloud, offlineData, onlineShadow, setOnlineShadow]);

  /**
   * Register firebase service worker changes
   */
  useEffect(() => {
    const syncFlag: Record<string, string | undefined> = {};

    swApi.onStateChange(function swOnMessageHandler(state) {
      Object.entries(state.batches).forEach(async ([key, batch]) => {
        if (batch.status === "done" && !syncFlag[key]) {
          syncFlag[key] = "done";
          localStorage.setItem(
            `firebase-precache.${user?.uid}.${key}`,
            new Date().toISOString(),
          );
          addToast("Offline Data Synced");
        }
      });
    });
  }, [addToast, user?.uid]);

  /**
   * Whenever uid changes. pre-cache offline documents
   */
  useEffect(() => {
    if (uid) {
      precacheUsersOfflineDocuments(uid);
    }
  }, [uid]);

  /**
   * Watch global user offlineStorage data, and sync when appropriate
   */
  useEffect(() => {
    const consents = globalUser?.offlineStorage_2 || {};

    Object.entries(consents).forEach(async ([key, consent]) => {
      await Promise.all([
        ...(consent.forms
          ? [
              syncOffline({
                key,
                type: "forms",
                uid: user!.uid,
                consent: consent.forms,
                target: consent.target,
              }),
            ]
          : []),
        ...(consent.documents
          ? [
              syncOffline({
                key,
                type: "documents",
                uid: user!.uid,
                consent: consent.documents,
                target: consent.target,
              }),
            ]
          : []),
      ]);
    });
    // eslint-disable-next-line
  }, [globalUser]);

  const api = useMemo<OfflineSupportContextApi>(
    () => ({
      offline: !online,
      offlineEvent: offlineData,
      pushOfflineDataRequest,
      upsertOfflineData,
      syncWithCloud,
    }),
    [
      online,
      offlineData,
      upsertOfflineData,
      pushOfflineDataRequest,
      syncWithCloud,
    ],
  );

  return (
    <OfflineSupportContext.Provider value={api}>
      {children}
    </OfflineSupportContext.Provider>
  );
};

export const useOfflineSupport = () => {
  const store = useContext(OfflineSupportContext);
  return store;
};
