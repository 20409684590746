import { IField, IFieldTypes } from "../typings";

export const validDateFormats = [
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "DD-MM-YYYY",
  "MM-DD-YYYY",
];

export const dateFormatField: IField = {
  fieldType: IFieldTypes.select,
  name: "DATE_FORMAT",
  label: "Date Format",
  description: "Set the format of dates",
  defaultValue: "DD/MM/YYYY",
  options: [
    {
      key: "DD/MM/YYYY",
      value: "DD/MM/YYYY",
      label: "Standard (DD/MM/YYYY)",
    },
    {
      key: "DD-MM-YYYY",
      value: "DD-MM-YYYY",
      label: "Standard (DD-MM-YYYY)",
    },
    {
      key: "MM/DD/YYYY",
      value: "MM/DD/YYYY",
      label: "American (MM/DD/YYYY)",
    },
    {
      key: "MM-DD-YYYY",
      value: "MM-DD-YYYY",
      label: "American (MM-DD-YYYY)",
    },
  ],
};
