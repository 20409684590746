// utilities
// eslint-disable-next-line
import firebasePreCacheWorker from "./firebase-pre-cache.worker?worker&url";

// types
import type { FirebaseDocumentPrecache, QueueItem } from "./types";

/**
 *
 *
 * Firebase Document Precache Api
 *
 *
 * This is the client side api for its corresponding service worker
 */
class FirebaseDocumentPreCacheApi {
  firebasePreCache: Worker;
  state: FirebaseDocumentPrecache;

  constructor() {
    this.firebasePreCache = new Worker(firebasePreCacheWorker, {
      type: "module",
    });
    this.state = {
      errors: [],
      batches: {},
      queueLength: 0,
      status: "done",
    };
    this.firebasePreCache.onmessage = (ev) => {
      this.state = ev.data as FirebaseDocumentPrecache;
    };
  }

  push(items: QueueItem[], batch: string) {
    this.firebasePreCache.postMessage({ type: "push", items, batch });
  }

  onStateChange(cb: (state: FirebaseDocumentPrecache) => void) {
    this.firebasePreCache.onmessage = (ev) => {
      this.state = ev.data;
      cb(this.state);
    };
    cb(this.state);
  }
}

export const swApi = new FirebaseDocumentPreCacheApi();
