import React, { useState, useEffect, useContext } from "react";

const ConnectivityStatusContext = React.createContext(true);

export const ConnectivityStatusProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    setOnlineStatus(navigator.onLine);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return (
    <ConnectivityStatusContext.Provider value={onlineStatus}>
      {children}
    </ConnectivityStatusContext.Provider>
  );
};

export const useConnectivityStatus = () => {
  const store = useContext(ConnectivityStatusContext);
  return store;
};
