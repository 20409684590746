import { QueryClientProvider } from "@tanstack/react-query";

// query client
import { queryClient, subscriptionQueryClient } from "./query-client";

export function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <QueryClientProvider client={subscriptionQueryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </QueryClientProvider>
  );
}
