import { doc, getDocFromCache, getDocFromServer } from "firebase/firestore";
import { firestore, _firebase } from "../../services/firebase.init";

// types
import { QueueItem } from "./types";
import { USERS_COLLECTION } from "wombat-global/src/constants";

export async function resolveQueueItem(
  task: QueueItem,
  completeTask: (_task: QueueItem) => void = () => null,
  errorTask: (_task: QueueItem, err: unknown) => void = () => null,
) {
  try {
    const clientDoc = await getDocFromCache(doc(firestore, task.path)).catch(
      () => undefined,
    );

    // console.log(clientDoc?.data()?.publishedAt, {publishedAt: task.meta.publishedAt})
    // console.log(clientDoc?.data()?.builtAt, { builtAt: task.meta.builtAt} )
    // console.log(clientDoc?.data(), task.meta )

    /**
     * If client doc has the same published date as the server doc published date. skip server fetch
     */
    if (
      (task.meta.builtAt && clientDoc?.data()?.builtAt >= task.meta.builtAt) || // prettier-ignore
      (task.meta.updatedAt && clientDoc?.data()?.updatedAt >= task.meta.updatedAt) || // prettier-ignore
      (task.meta.createdAt && clientDoc?.data()?.createdAt == task.meta.createdAt) || // prettier-ignore
      (task.meta.publishedAt && clientDoc?.data()?.publishedAt >= task.meta.publishedAt) // prettier-ignore
    ) {
      completeTask(task);
      // eslint-disable-next-line
      console.log(task.path, "hit - up to date");
      return clientDoc?.data();
    }

    const d = await getDocFromServer(doc(firestore, task.path));
    // eslint-disable-next-line
    console.log(task.path, "cached", d.data());
    if (task.path.includes(USERS_COLLECTION + "/") && d.data() === undefined) {
      // eslint-disable-next-line
      console.error("Cache error retreiving user", d);
      throw Error("undefined user found in cache");
    }
    completeTask(task);
    return d.data();
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    errorTask(task, err);
  } finally {
    // eslint-disable-next-line
    // console.log(task.path, "finally");
  }
}
