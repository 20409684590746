// hooks
import { useOnPageLoad } from "../hooks/useRoutes";

// MUI
import ErrorComponent from "../components/404ErrorComponent";

const Reports: React.FC = () => {
  useOnPageLoad();

  return <ErrorComponent />;
};

export const config = {
  private: false,
};

export default Reports;
