import { IFieldGroupBuilt, IFieldTypes, ITaskSubTypes } from "../../typings";

export const TASK_CREATION_FORM: IFieldGroupBuilt = {
  fields: [
    {
      fieldType: IFieldTypes.textField,
      autoFocus: true,
      name: "title",
      label: "Title",
      required: true,
      span: 2,
    },
    {
      fieldType: IFieldTypes.textField,
      autoFocus: false,
      name: "name",
      label: "System Name",
      required: true,
      span: 2,
    },
    {
      fieldType: IFieldTypes.textArea,
      name: "description",
      label: "Description",
      description: "What this type of task will be used for",
      max: 2500,
      defaultValue: "",
      span: 4,
    },
    {
      fieldType: IFieldTypes.checkbox,
      name: "metadata.correctiveActionFormOnly",
      label: "This Corrective Action is only accessible from within a form?",
      span: 4,
      required: false,
      conditions: [
        {
          parameter: "subType",
          operation: "==",
          value: ITaskSubTypes["corrective-action"],
          action: "visible",
        },
      ],
    },
    {
      fieldType: IFieldTypes.autocomplete,
      freeSolo: true,
      name: "subType",
      label: "Task Type",
      span: 4,
      required: true,
      options: [
        {
          value: ITaskSubTypes["corrective-action"],
          label: "Corrective Action",
        },
        { value: ITaskSubTypes.issue, label: "Issue" },
      ],
    },
  ],
  min: 1,
  max: 1,
  label: "task-creation",
  name: "TaskCreation",
};
