// types
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

// TODO: Leaving the id as "add-person" for consistency with the form title.
export const ADD_USER_FORM: IFieldGroupBuilt = {
  name: "addPerson",
  label: "Add Person",
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 1,
      name: "metadata.firstName",
      label: "First Name",
      required: true,
    },
    {
      fieldType: IFieldTypes.textField,
      span: 1,
      name: "metadata.middleName",
      label: "Middle Name",
    },
    {
      fieldType: IFieldTypes.textField,
      span: 1,
      name: "metadata.lastName",
      label: "Last Name",
      required: true,
    },
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "displayName",
      label: "Display Name",
    },
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "metadata.employeeId",
      label: "Employee Number",
    },
    {
      fieldType: IFieldTypes.textField,
      span: 2,
      name: "email",
      label: "Email",
      required: true,
    },
    {
      fieldType: IFieldTypes.password,
      span: 2,
      name: "password",
      label: "Password",
    },
    {
      fieldType: IFieldTypes.toggle,
      span: 2,
      name: "active",
      label: "Active",
      defaultValue: true,
    },
  ],
};
