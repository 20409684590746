/**
 * This is used within a field or group to determine when to show that field or group.
 */
export type TFieldValue = string | number | boolean | undefined | null;

// eslint-disable-next-line no-shadow
export enum IConditionComparisonOperationsEnum {
  "noteq" = "!=",
  "eq" = "==",
  "in" = "in",
  "notin" = "notin",
  "includes" = "includes",
  "excludes" = "excludes",
  "intersects" = "intersects",
  "XOR" = "XOR",
}

export type IConditionComparisonOperations =
  | IConditionComparisonOperationsEnum
  | "!="
  | "=="
  | "in"
  | "notin"
  | "excludes"
  | "includes"
  | "intersects"
  | "XOR";

// eslint-disable-next-line no-shadow
export enum IConditionActionsEnum {
  "hidden" = "hidden",
  "disabled" = "disabled",
  "readonly" = "readonly",
  "visible" = "visible",
}
export type IConditionActions =
  | "hidden"
  | "disabled"
  | "readonly"
  | "visible"
  | IConditionActionsEnum;

export interface ICondition {
  value: TFieldValue | TFieldValue[];
  parameter: string;
  operation: IConditionComparisonOperations;
  action?: IConditionActions;
}
