// flags
import { FLAGS_MAP } from "../feature-flags.maps";

// services
export { defaultConfig, alwaysOnFeatures } from "./default";

// types
import { RemoteConfigType } from "./types";

export type { RemoteConfigType } from "./types";

export function deriveFeatureFlag(config: RemoteConfigType) {
  return Object.keys(config)
    .filter((key) => !!FLAGS_MAP[key as keyof typeof FLAGS_MAP])
    .filter((key) => !!config[key as keyof RemoteConfigType])
    .reduce(
      (cur, key) => FLAGS_MAP[key as keyof typeof FLAGS_MAP].bitFlag | cur,
      0,
    );
}

export function parseRemoteFetch(
  defaultConfig: RemoteConfigType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remote: Record<string, any>,
): RemoteConfigType {
  return Object.entries(remote).reduce(
    (conf, [key, val]) => {
      switch (typeof defaultConfig[key as keyof typeof defaultConfig]) {
        case "boolean":
          conf[key] = val.asBoolean();
          break;
        case "number":
          conf[key] = val.asNumber();
          break;
        default:
          conf[key] = val.asString();
      }
      return conf;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { ...defaultConfig } as any,
  ) as RemoteConfigType;
}
