// types
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const ADDRESS_FORM_FIELDS: IFieldGroupBuilt = {
  name: "address",
  label: "Address",
  min: 1,
  max: 3,
  fields: [
    {
      name: "line1",
      label: "Line 1",
      span: 4,
      fieldType: IFieldTypes.textField,
    },
    {
      name: "line2",
      label: "Line 2",
      span: 4,
      fieldType: IFieldTypes.textField,
    },
    {
      name: "city",
      label: "City",
      span: 1,
      fieldType: IFieldTypes.textField,
    },
    {
      name: "country",
      span: 1,
      label: "Country",
      fieldType: IFieldTypes.textField,
    },
    {
      name: "state",
      span: 1,
      label: "State/Province",
      fieldType: IFieldTypes.textField,
    },
    {
      name: "postalCode",
      label: "Zip/Postal Code",
      span: 1,
      fieldType: IFieldTypes.textField,
    },
  ],
};
