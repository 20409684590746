// hoks
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";
import { useQuery } from "@tanstack/react-query";

// types
import { IClientTarget, ISiteTarget } from "wombat-global/src/typings";

export function useIsAdmin(
  target: IClientTarget | ISiteTarget,
): [boolean, boolean] {
  const { uid, isAdmin } = useAuthenticationContext();
  const { data: _isAdmin, isLoading } = useQuery({
    queryKey: [target, uid],
    queryFn: () => {
      return isAdmin(target);
    },
  });

  return [_isAdmin || false, isLoading];
}
