// libraries
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase.service";

// constants
import { GLOBAL_USERS_COLLECTION } from "wombat-global/src/constants";
import { IGlobalUserEntity } from "wombat-global/src/typings/users";

export function getGlobalUserDocument(
  userId: string,
): Promise<IGlobalUserEntity> {
  return getDoc(doc(firestore, GLOBAL_USERS_COLLECTION, userId)).then(
    (document) => document.data() as IGlobalUserEntity,
  );
}
