// libraries
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import {
  signInWithEmailAndPassword,
  User,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";

// services
import request from "../../services/request.service";
import { auth } from "../../services/firebase.service";

// contexts
import { useDataStore } from "../dataStore.context";
import { SubscriptionContext } from "../subscription/subscriptions.context";

// utilities
import { hasPermission } from "wombat-global/src/utilities/permissions";
import { getSiteDocument } from "../../services/firebase/getTenant";

// constants
import { ADMIN } from "wombat-global/src/permission.maps";

// types
import { TDecodedToken } from "./authentication.types";
import {
  IClientTarget,
  ISiteTarget,
  SiteEntity,
  isSiteTarget,
} from "wombat-global/src/typings";

// eslint-disable-next-line max-len
// https://wombat-development.firebaseapp.com/__/auth/handler?apiKey=AIzaSyAJWMU6Z3OpoXi3HWEXbYWxy4soJd94l0E&appName=%5BDEFAULT%5D&authType=signInViaPopup&redirectUrl=https%3A%2F%2Fwombat-development.firebaseapp.com%2Flogin&v=10.12.2&eventId=5581279086&providerId=google.com&scopes=profile%2Chttps%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcontacts.readonly

type AuthenticationContextType = {
  isAuthenticated: boolean;
  isAdmin: (target: IClientTarget | ISiteTarget) => Promise<boolean>;
  hasPermission: (
    target: IClientTarget | ISiteTarget,
    permissionFlag: number,
  ) => boolean;
  // add an isAdmin call to check if user is a target admin (site checks will get site docs and check owner user status)
  logout: () => void;
  reload: () => void;
  sso: (
    provide: "google" | "microsoft",
  ) => Promise<void | [User, TDecodedToken]>;
  login: (
    email: string,
    password: string,
  ) => Promise<void | [User, TDecodedToken]>;
  initialized: boolean;
  user: User | null;
  uid?: string;
  authToken: TDecodedToken | null;
  acceptedTC?: string;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  isAuthenticated: false,
  hasPermission: () => false,
  isAdmin: async () => false,
  logout: async () => null,
  login: async () => {
    return;
  },
  sso: async () => {
    return;
  },
  reload: async () => null,
  initialized: false,
  user: null,
  uid: undefined,
  authToken: null,
});

const msftProvider = new OAuthProvider("microsoft.com");
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("profile");
googleProvider.addScope("email");
googleProvider.setCustomParameters({ prompt: "select_account" });

export const AuthenticationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { cleanStoreData } = useDataStore();
  const { cleanSubscriptions } = useContext(SubscriptionContext);
  const [authToken, setAuthToken] = useState<TDecodedToken | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setAuthentication] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [tokenExpiresAt, setTokenExpiresAt] = useState<number>( new Date().getTime() + 3600 * 1000); // prettier-ignore

  /**
   * @private
   *
   * This should be used within the login, onAuth, & reload calls and must be Idempotent
   */
  const setUserAuthentication = useCallback(
    (_user: User, token: TDecodedToken) => {
      token && request.setAccessToken(token.token);

      setTokenExpiresAt(token ? new Date(token.expirationTime).getTime() : -1);
      setAuthToken(token);
      setUser(_user);
      setAuthentication(true);
    },
    [],
  );

  /**
   * @public
   *
   * useHasPermission checks if the current user has specified permission (encoded in permission bit flag)
   */
  const userHasPermission = useCallback(
    (target: IClientTarget | ISiteTarget, permissionFlag: number) => {
      if (!authToken?.claims) {
        return false;
      }
      return hasPermission(target, authToken.claims, permissionFlag);
    },
    [authToken],
  );

  const isAdmin = useCallback(
    async (target: IClientTarget | ISiteTarget) => {
      if (!authToken?.claims) {
        return false;
      }
      let allowed = hasPermission(target, authToken.claims, ADMIN.bitFlag);
      if (isSiteTarget(target) && !allowed) {
        const site = await getSiteDocument(target).then(
          (d) => d.data() as SiteEntity,
        );
        allowed = hasPermission(site.owner, authToken.claims, ADMIN.bitFlag);
      }
      return allowed;
    },
    [authToken],
  );

  /**
   * @public
   *
   * reload will reload the user auth token with the new claim (use when user claim is updated in the BE)
   */
  const reload = useCallback(async () => {
    if (auth.currentUser) {
      const token = (await auth.currentUser.getIdTokenResult(
        true,
      )) as TDecodedToken;
      setUserAuthentication(auth.currentUser, token);
    }
  }, [setUserAuthentication]);

  /**
   * @public
   *
   * logout
   */
  const logout = useCallback(async () => {
    await auth.signOut();
    request.setAccessToken(undefined);
    setAuthentication(false);
    setTokenExpiresAt(-1);
    setAuthToken(null);
    setUser(null);
    cleanSubscriptions();
    cleanStoreData();
    return;
  }, [cleanStoreData, cleanSubscriptions, setAuthentication]);

  /**
   * @public
   *
   * login functions
   */
  const login = useCallback(
    async (
      email: string,
      password: string,
    ): Promise<void | [User, TDecodedToken]> => {
      await signInWithEmailAndPassword(auth, email, password);
      const userEntity = auth.currentUser;

      // decode token
      const tokenResult =
        (await auth.currentUser?.getIdTokenResult()) as TDecodedToken;

      if (userEntity) {
        cleanStoreData();
        await setUserAuthentication(userEntity, tokenResult);
        return [userEntity, tokenResult];
      }
      return;
    },
    [cleanStoreData, setUserAuthentication],
  );

  const sso = useCallback(
    async (
      providerType: "google" | "microsoft",
    ): Promise<void | [User, TDecodedToken]> => {
      let ssoUser;
      if (providerType === "google") {
        const result = await signInWithPopup(auth, googleProvider);
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        ssoUser = result.user;
      } else {
        const result = await signInWithPopup(auth, msftProvider);
        // const credential = OAuthProvider.credentialFromResult(result);
        ssoUser = result.user;
        // IdP data available using getAdditionalUserInfo(result)
      }

      if (ssoUser) {
        const token = (await ssoUser?.getIdTokenResult()) as TDecodedToken;

        cleanStoreData();
        await setUserAuthentication(ssoUser, token);
        return [ssoUser, token];
      }

      return;
    },
    [cleanStoreData, setUserAuthentication],
  );

  useEffect(() => {
    // this isn't just a subscription to an Observable. It emits the currentUser when this listener is attached
    // like a behavior subject, therefore, theres no change to have any race condition between firebase's internal
    // auth check, and adding this listener.
    // - this callback is called after the auth().signInXX method. This means theres a potential for a race
    //  condition. To avoid this create a login method that duplicates the same necessary authentication process.
    // - this callback is called after the auth().signInXX method. This means theres a potential for a race
    //  condition. To avoid this create a login method that duplicates the same necessary authentication process.
    const authUnsubscribe = auth.onAuthStateChanged(
      async function (userEntity) {
        // const id = Date.now().toString();
        if (userEntity) {
          try {
            // decode access token
            // console.log(id, "starting fetch");

            const tokenResult =
              (await auth.currentUser?.getIdTokenResult()) as TDecodedToken;

            await setUserAuthentication(userEntity, tokenResult);
            // console.log(id, "successful fetch");
          } catch (err) {
            //
          }
        } else {
          // console.log(id, "user subUpdate", userEntity);
          setAuthentication(false);
        }
        // console.log(id, "END setInitialized");
        setInitialized(true);
      },
    );

    return authUnsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if tokenExpiresAt is negative ignore refresh
    if (tokenExpiresAt < 0) {
      return;
    }

    // ms of time before token expiration
    const expiresIn = tokenExpiresAt - new Date().getTime();

    const timePadding = 10 * 1000; // 10 sec

    const refreshAuthTokenJob = setTimeout(async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const token = await currentUser.getIdTokenResult(true);
        setUserAuthentication(currentUser, token as TDecodedToken);
      }
    }, expiresIn - timePadding);

    return () => {
      clearTimeout(refreshAuthTokenJob);
    };
  }, [setUserAuthentication, tokenExpiresAt]);

  /*
   * Notes: Is the use of useMemo necessary here?
   * IMO yes. Without the memoizing the value passed into the provider an unintentional re-render can be
   * triggered without any value within the object having been changed; as a result, any child component would
   * be re-render. This is because defining the an object within a Components property will create a new
   * version of that object no matter the change (or not) of its content. Possible unintentional triggers
   * would be a parent component re-rendering or an internal method / object (async call?) updating.
   *
   * Its unlikely that a parent to this component will re-render and its internals are simple enough to
   * guarantee they wont cause a re-render, but we add it here to set a standard for other Contexts
   */
  const providerState = useMemo(
    () => ({
      isAuthenticated,
      initialized,
      reload,
      logout,
      login,
      sso,
      hasPermission: userHasPermission,
      isAdmin,
      acceptedTC: authToken?.claims?.tc,
      user,
      uid: user?.uid,
      authToken,
    }),
    [
      isAuthenticated,
      initialized,
      reload,
      logout,
      login,
      sso,
      userHasPermission,
      isAdmin,
      authToken,
      user,
    ],
  );

  return (
    <AuthenticationContext.Provider value={providerState}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): AuthenticationContextType => {
  return useContext(AuthenticationContext);
};
