import { useCallback, useEffect, useState } from "react";
import { useStore } from "../contexts/dataStore.context";

export function useLocalStorage<T = unknown>(
  key: string,
  initialValue: T,
  options: {
    removeOnLogout?: boolean;
  } = { removeOnLogout: false },
): [T | typeof initialValue, (val: T | ((v: T) => T)) => void] {
  type FuncT = (v: T) => T;

  const [keys, setKeys] =
    useStore<Record<string, unknown>>("localStorageKeyMap");

  useEffect(() => {
    if (options?.removeOnLogout && !keys[key]) {
      setKeys({ [key]: true }, { merge: true });
    }
  }, [options?.removeOnLogout, keys, key, setKeys]);

  const [state, setLocalState] = useState<T>(() => {
    const ds = localStorage.getItem(key);
    if (ds && typeof JSON.parse(ds).value == typeof initialValue) {
      return JSON.parse(ds).value;
    }
    return initialValue;
  });

  const changeHandler = useCallback(
    function innerChangeHandler(val: T | FuncT) {
      if (typeof val == "function") {
        setLocalState((v) => {
          localStorage.setItem(
            key,
            JSON.stringify({ value: (val as FuncT)(v) }),
          );
          return (val as FuncT)(v);
        });
      } else {
        setLocalState(val);
        localStorage.setItem(key, JSON.stringify({ value: val }));
      }
    },
    [key],
  );
  return [state, changeHandler];
}
