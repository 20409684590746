import { IFieldTypes, IFieldGroupBuilt, IField } from "../../typings";
import { spanField } from "./field-schema-form";

const dashboardTitle: IField = {
  fieldType: IFieldTypes.textField,
  name: "title",
  label: "Dashboards Title",
  required: true,
  autoFocus: true,
  span: 2,
};

export const UPDATE_DASHBOARD_FORM: IFieldGroupBuilt = {
  fields: [dashboardTitle],
  groups: [],
  min: 1,
  max: 1,
  label: "DashboardProperties",
  name: "dashboard-properties",
};

export const UPDATE_FIGURE_PROPERTIES: IFieldGroupBuilt = {
  fields: [
    // {
    //   fieldType: IFieldTypes.textField,
    //   name: "title",
    //   label: "Figure Title",
    //   required: true,
    //   autoFocus: true,
    //   span: 2,
    // },
    spanField,
    {
      fieldType: IFieldTypes.textField,
      name: "title",
      label: "Figure Title",
      required: true,
      autoFocus: true,
      span: 2,
    },
  ],
  groups: [
    {
      fields: [
        // {
        //   fieldType: IFieldTypes.autocomplete,
        //   name: "figureType",
        //   label: "Type",
        //   required: true,
        //   options: [
        //     {
        //       label: "Count",
        //       value: "count",
        //     },
        //     {
        //       label: "Pie Chart",
        //       value: "pie",
        //     },
        //     {
        //       label: "Bar Chart",
        //       value: "bar",
        //     },
        //   ],
        // },
        // {
        //   fieldType: IFieldTypes.autocomplete,
        //   name: "es_index",
        //   label: "Data Types",
        //   description: "Objects include both forms and tasks",
        //   required: true,
        //   options: [
        //     {
        //       label: "Object",
        //       value: "object",
        //     },
        //     {
        //       label: "Training",
        //       value: "training",
        //     },
        //     {
        //       label: "Users",
        //       value: "user",
        //     },
        //   ],
        // },
        // {
        //   fieldType: IFieldTypes.autocomplete,
        //   name: "labelType",
        //   label: "Label Transformations",
        //   description:
        //     "Configure the graph to transform the x variable (date to Month or userId to User name)",
        //   options: [
        //     {
        //       label: "Date Month",
        //       value: "date_month",
        //     },
        //     {
        //       label: "User Name",
        //       value: "user",
        //     },
        //     {
        //       label: "Form Name",
        //       value: "object_id",
        //     },
        //   ],
        // },
        // {
        //   fieldType: IFieldTypes.textField,
        //   name: "must",
        //   label: "must",
        //   required: true,
        // },
        // field
        // aggregations
        // must
        // must_not
      ],
      name: "figures",
      label: "Figures",
      min: 1,
      max: 1,
    },
  ],
  min: 1,
  max: 1,
  label: "Figure Properties",
  name: "figure-properties",
};
